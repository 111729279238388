import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import LockIcon from 'src/images/paywall/lock.svg?react';
import PayButton from 'src/components/Paywall/PayButton';
import MonthlyOffer from 'src/components/Paywall/MonthlyOffer';
import Button from 'src/components/common/designSystem/Button';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics';
import AskParentButton from 'src/components/Paywall/AskParentButton';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';

const PaywallPanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent('paywall_viewed', {
        type: 'blocking_trial_ended',
      });
      logAnalyticsEvent('trial_ended');
    }
  }, [isOpen]);

  return (
    <AutoFloatingPanel
      data-testid={'panel-paywall-locked'}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isOpen ? (
        <div
          className={
            'absolute w-full -top-4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center'
          }
        >
          <div
            className={'text-center text-balance text-white font-bold text-lg'}
          >
            {t('paywall.locked.description')}
          </div>
          <LockIcon />
        </div>
      ) : null}
      <div className={'flex flex-col items-center justify-center p-6 gap-8'}>
        <div
          className={
            'font-bold text-xl text-structural-darker text-center text-balance'
          }
        >
          {t('paywall.locked.title')}
        </div>

        <MonthlyOffer />

        <div
          className={'flex flex-col items-center justify-center gap-2 w-full'}
        >
          <PayButton>{t('global.continue')}</PayButton>
          <AskParentButton />
          <Button
            data-testid={'btn-skip-paywall'}
            type={'default'}
            onClick={onClose}
          >
            {t('paywall.pay.buttonSkip')}
          </Button>
          <div
            className={'text-structural-dark text-xs text-center text-balance'}
          >
            {t('paywall.locked.footer')}
          </div>
          <PaywallCGU />
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default PaywallPanel;
