import { useTranslation } from 'react-i18next';
import LessonCardEvaluationDate from 'src/components/LessonsLists/LessonCardEvaluationDate';
import { lessonIsProcessing } from 'src/utils/lessonsUtils';
import { useGetLesson } from 'src/queries/lessons';
import Spinner from 'src/components/common/designSystem/Spinner';
import QuizScore from 'src/components/Quiz/QuizScore';
import { Lesson } from 'src/types/lesson.types';

export default function LessonCardHeader({ lesson }: { lesson: Lesson }) {
  const { t } = useTranslation();
  const { data: lessonData } = useGetLesson(lesson.id);
  const lastUpdatedLesson = lessonData ?? lesson;
  const isProcessing = lessonIsProcessing(lastUpdatedLesson);

  const runs = lesson.latest_quiz?.runs;
  const maxScore = runs?.length
    ? Math.max(...runs.map(run => run.grade))
    : undefined;

  if (isProcessing) {
    return (
      <div className={'flex flex-row items-center justify-start w-full gap-1'}>
        <Spinner type={'white'} size={'small'} />
        <div className={'uppercase'}>{t('lessons.status.processing')}</div>
      </div>
    );
  }

  return (
    <div className={'flex flex-row justify-between items-center w-full'}>
      {maxScore ? (
        <QuizScore score={maxScore} textColor={'text-white'} />
      ) : (
        <div className={'flex flex-row gap-2 items-center'}>
          <div className={'rounded-full w-3 h-3 bg-[#A0F6A3]'} />
          <div className={'text-balance font-bold text-base'}>
            {t('lessons.status.ready')}
          </div>
        </div>
      )}
      {lesson.evaluation_date ? (
        <LessonCardEvaluationDate evaluationDate={lesson.evaluation_date} />
      ) : null}
    </div>
  );
}
