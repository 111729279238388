import PaywallCheckIcon from 'src/components/Paywall/PaywallCheckIcon';

function CheckboxList<ValueType extends string | number>({
  options,
  selected,
  onSelect,
}: {
  options: Array<{ label: string; value: ValueType }>;
  selected: ValueType[];
  onSelect: (value: ValueType[]) => void;
}) {
  return (
    <div className="flex-1 flex flex-col gap-2 justify-center w-full">
      {options.map(option => {
        const isSelected = selected.includes(option.value) ?? false;
        return (
          <div
            key={option.value}
            data-testid={`option-${option.value}`}
            onClick={() => {
              if (isSelected) {
                onSelect(selected.filter(value => value !== option.value));
              } else {
                onSelect([...selected, option.value]);
              }
            }}
            className={`w-full items-center ${isSelected ? 'bg-gradient-to-r' : 'bg-[#F5F5F5]'} from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-0.5`}
          >
            <div
              className={`p-3 flex rounded-[10px] bg-[#F5F5F5] items-center justify-between text-base font-extrabold w-full`}
            >
              <div>{option.label}</div>
              {isSelected ? <PaywallCheckIcon /> : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CheckboxList;
