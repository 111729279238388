import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateCurrentUser } from 'src/queries/user';
import { useNotification } from 'src/contexts/NotificationContext';
import { logAnalyticsEvent } from 'src/modules/analytics';

const useEndOnboardingParent = () => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateCurrentUser,
    isLoading: finishingParentOnboarding,
  } = useUpdateCurrentUser();
  const { showError } = useNotification();

  const endOnboarding = useCallback(async () => {
    try {
      await updateCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('onboarding_complete');
    } catch (error) {
      console.error(error);
      showError({
        message: t('error.http.default'),
        error,
      });
    }
  }, [updateCurrentUser, showError, t]);

  return { endOnboarding, isEnding: finishingParentOnboarding };
};

export default useEndOnboardingParent;
