import { getSubjectInfo } from 'src/utils/subject';
import { Lesson } from 'src/types/lesson.types';

const LessonCardIcon = ({ lesson }: { lesson: Lesson }) => {
  const subject = getSubjectInfo(lesson.subject_matter);

  return (
    <div
      className={
        'w-44 h-44 relative overflow-hidden rounded-xl flex items-center justify-center p-2.5'
      }
    >
      <subject.IconComponent className={'w-full h-full'} />
    </div>
  );
};

export default LessonCardIcon;
