import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { copyDeep } from 'src/utils/helper-functions';
import blobToHash from 'blob-to-hash';
import { invalidateGetLessonsQueries } from './lessons';
import { logAnalyticsEvent } from 'src/modules/analytics';
import { uploadFile } from 'src/modules/storage';
import { Lesson, LessonPiece } from 'src/types/lesson.types';

export enum PieceType {
  PHOTO = 'photo',
  VOICE = 'voice',
}

const getStorageRef = (type: PieceType, hashBuffer: string) =>
  `lessons/${type}/${hashBuffer}`;

export const useCreateLessonPiece = ({ type }: { type: PieceType }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      lessonId,
      file,
      title,
      uri,
    }: {
      lessonId: number | string;
      file: Blob;
      title: string;
      uri?: string;
    }) => {
      const hashBuffer = await blobToHash('sha256', file);
      const storageRef = getStorageRef(type, hashBuffer);

      await uploadFile({
        path: storageRef,
        blob: file,
        uri,
      });

      const { data } = await axios.post(
        `/api/lessons/${lessonId}/lesson_pieces`,
        {
          title,
          storage_reference: storageRef,
        },
      );
      return data as LessonPiece;
    },
    onSuccess: (lessonPieceData): any => {
      const lessonData: Lesson | undefined = queryClient.getQueryData([
        'lessons',
        Number(lessonPieceData.lesson_id),
      ]);
      if (!lessonData) return;
      const lessonCopy = copyDeep(lessonData);
      lessonCopy.lesson_pieces.push(lessonPieceData);
      invalidateGetLessonsQueries(queryClient);
      queryClient.setQueryData(
        ['lessons', Number(lessonPieceData.lesson_id)],
        lessonCopy,
      );
      logAnalyticsEvent('lesson_piece_created', {
        lessonId: lessonPieceData.lesson_id,
        lessonPieceId: lessonPieceData.id,
      });
    },
  });
};

export const useGetLessonPiece = (lessonId: number, lessonPieceId: number) => {
  return useQuery({
    queryKey: ['lesson_pieces', lessonPieceId],
    queryFn: async () => {
      const { data } = await axios.get<LessonPiece>(
        `/api/lessons/${lessonId}/lesson_pieces/${lessonPieceId}`,
      );
      return data;
    },
    refetchInterval: lessonPiece => {
      const hasTranscription = lessonPiece?.raw_text_content
        ? lessonPiece.raw_text_content.length > 0
        : false;
      if (hasTranscription) {
        return false;
      }
      return 1000;
    },
  });
};
