import {
  CustomerInfo,
  LOG_LEVEL,
  PACKAGE_TYPE,
  PRODUCT_TYPE,
  Purchases,
  PurchasesEntitlementInfo,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { getAnalyticsDeviceId } from 'src/modules/analytics';
import { User } from 'src/types/user.types';

export const initInAppPurchase = async () => {
  if (Capacitor.getPlatform() === 'web') {
    await Purchases.setMockWebResults({ shouldMockWebResults: true });
  }
  await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
  if (Capacitor.getPlatform() === 'ios') {
    await Purchases.configure({
      apiKey: 'appl_gfNrmAOtFfJjCadVHLxaSfxjzOj',
    });
  }
  if (Capacitor.getPlatform() === 'android') {
    await Purchases.configure({
      apiKey: 'goog_QXvQYkNfgDgpdvAieZIVpYgbRhz',
    });
  }
};

const fakePackage: PurchasesPackage = {
  identifier: 'premium_trial',
  packageType: PACKAGE_TYPE.MONTHLY,
  product: {
    title: 'Premium',
    description: 'Premium subscription',
    price: 0.99,
    pricePerMonth: 0.99,
    pricePerWeek: 0.99,
    pricePerMonthString: '$0.99',
    pricePerYear: 0.99,
    pricePerWeekString: '$0.99',
    pricePerYearString: '$0.99',
    currencyCode: 'USD',
    identifier: 'premium_trial',
    introPrice: null,
    productType: PRODUCT_TYPE.AUTO_RENEWABLE_SUBSCRIPTION,
    discounts: null,
    productCategory: null,
    priceString: '$0.99',
    defaultOption: null,
    subscriptionOptions: null,
    subscriptionPeriod: null,
    presentedOfferingContext: null,
    presentedOfferingIdentifier: null,
  },
  presentedOfferingContext: {
    offeringIdentifier: 'default',
    targetingContext: null,
    placementIdentifier: null,
  },
  offeringIdentifier: 'default',
};

export const subscribePremiumWithTrial = async () => {
  if (import.meta.env.VITE_CI) {
    const result = await Purchases.purchasePackage({ aPackage: fakePackage });
    const fakeCustomerInfo = await getFakeCustomerInfo();
    if (fakeCustomerInfo) {
      return {
        ...result,
        customerInfo: fakeCustomerInfo,
      };
    }
    return result;
  }

  const { all } = await Purchases.getOfferings();
  if (!all || !all['default']) {
    throw new Error('No offerings available');
  }
  return Purchases.purchasePackage({
    aPackage: all['default'].availablePackages[0],
  });
};

export const subscribePremiumWithoutTrial = async () => {
  if (import.meta.env.VITE_CI) {
    const result = await Purchases.purchasePackage({ aPackage: fakePackage });
    const fakeCustomerInfo = await getFakeCustomerInfo();
    if (fakeCustomerInfo) {
      return {
        ...result,
        customerInfo: fakeCustomerInfo,
      };
    }
    return result;
  }

  const { all } = await Purchases.getOfferings();
  if (!all || !all['without_trial']) {
    throw new Error('No offerings available');
  }
  return Purchases.purchasePackage({
    aPackage: all['without_trial'].availablePackages[0],
  });
};

export const logInAppPurchase = async (user: User) => {
  if (!user.family_id || !user.id) {
    return;
  }
  await Purchases.logIn({
    appUserID: user.family_id.toString(),
  });
  await Purchases.setAttributes({
    userID: user.id.toString(),
    familyID: user.family_id.toString(),
    $amplitudeDeviceId: getAnalyticsDeviceId() ?? '',
    $amplitudeUserId: user.id.toString(),
  });
  await Purchases.invalidateCustomerInfoCache();
};

export const logOutInAppPurchase = async () => {
  try {
    const { isAnonymous } = await Purchases.isAnonymous();
    if (isAnonymous) {
      return;
    }
    await Purchases.logOut();
  } catch (e) {}
};

export const getFakeCustomerInfo = async () => {
  // @ts-ignore
  if (!import.meta.env.VITE_CI || !window.getFakeCustomerInfo) {
    return undefined;
  }
  // @ts-ignore
  return window.getFakeCustomerInfo();
};

export const getActiveSubscription = (
  customerInfo: CustomerInfo,
): PurchasesEntitlementInfo | undefined => {
  if (customerInfo.entitlements.active['premium']) {
    return customerInfo.entitlements.active['premium'];
  }
  if (customerInfo.entitlements.active['premium_without_trial']) {
    return customerInfo.entitlements.active['premium_without_trial'];
  }
  return undefined;
};

export const getPurchaseUserId = async () => {
  const { appUserID } = await Purchases.getAppUserID();
  return appUserID;
};
