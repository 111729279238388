import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ParentChildSchoolLevelStep from 'src/components/Onboarding/ParentChildSchoolLevelStep';

const OnboardingParentChildSchoolLevel = () => {
  const navigate = useNavigate();
  const { childId } = useParams();
  const [searchParams] = useSearchParams();
  const onNext = () => {
    navigate(
      `/onboarding/parent/child/${childId}/school_level/device?${searchParams.toString()}`,
    );
  };

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={4} />}
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ParentChildSchoolLevelStep childId={Number(childId)} onFinish={onNext} />
      <Outlet />
    </LayoutWithHeader>
  );
};

export default OnboardingParentChildSchoolLevel;
