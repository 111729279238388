import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCurrentUser } from 'src/queries/user';
import {
  CustomerInfo,
  MakePurchaseResult,
  Purchases,
  PurchasesEntitlementInfo,
} from '@revenuecat/purchases-capacitor';
import {
  getActiveSubscription,
  getFakeCustomerInfo,
  getPurchaseUserId,
  logInAppPurchase,
  logOutInAppPurchase,
  subscribePremiumWithoutTrial as subscribePremiumWithoutTrialApi,
  subscribePremiumWithTrial as subscribePremiumWithTrialApi,
} from 'src/modules/purchase';
import dayjs from 'dayjs';
import PaywallPanel from 'src/components/Paywall/PaywallPanel';
import { logAnalyticsEvent } from 'src/modules/analytics';
import StartTrialPanel from 'src/components/Paywall/StartTrialPanel';
import { User } from 'src/types/user.types';
import { Location, useLocation } from 'react-router-dom';

type PaywallType = {
  isPremium: boolean;
  isInitialized: boolean;
  nbTrialDays: number;
  isOutOfTrial: boolean;
  activeSubscription?: PurchasesEntitlementInfo;
  managementURL?: string;
  subscribePremiumWithTrial: () => Promise<MakePurchaseResult>;
  subscribePremiumWithoutTrial: () => Promise<MakePurchaseResult>;
  showPaywall: () => void;
  hidePaywall: () => void;
};

const PaywallContext = createContext<PaywallType | null>(null);

const DEFAULT_NB_TRIAL_DAYS = 14;
const BETA_TESTER_NB_TRIAL_DAYS = 30;

const PATHS_WITHOUT_PAYWALL = ['/terms-and-conditions', '/confidentialite'];
const ignorePaywall = (location: Location) => {
  return PATHS_WITHOUT_PAYWALL.some(path => location.pathname.includes(path));
};

const PaywallProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<{
    activeSubscription?: PurchasesEntitlementInfo;
    userId?: string;
    managementURL?: string;
    isOutOfTrial: boolean;
  }>({ isOutOfTrial: false });
  const [isPaywallOpen, setIsPaywallOpen] = useState<boolean>(false);
  const { data: user } = useCurrentUser();
  const callbackListenerID = useRef<string>();
  const isPremium = state?.activeSubscription?.isActive ?? false;
  const nbTrialDays = useRef<number>(DEFAULT_NB_TRIAL_DAYS);
  const location = useLocation();

  const customerInfoUpdateListener = useCallback(
    async (customerInfo: CustomerInfo) => {
      if (import.meta.env.VITE_CI) {
        const newCustomerInfo = await getFakeCustomerInfo();
        if (newCustomerInfo) {
          customerInfo = newCustomerInfo;
        }
      }
      const userId = await getPurchaseUserId();
      setState(state => ({
        ...state,
        userId,
        activeSubscription: getActiveSubscription(customerInfo),
        managementURL: customerInfo.managementURL ?? undefined,
      }));
    },
    [],
  );

  const initialize = useCallback(
    async (user?: User) => {
      try {
        nbTrialDays.current = DEFAULT_NB_TRIAL_DAYS;
        if (user) {
          const isBetaTester = dayjs(user.created_at).isBefore('2024-09-01');
          if (isBetaTester) {
            nbTrialDays.current = BETA_TESTER_NB_TRIAL_DAYS;
          }

          callbackListenerID.current =
            await Purchases.addCustomerInfoUpdateListener(
              customerInfoUpdateListener,
            );
          await logInAppPurchase(user);
        } else {
          await logOutInAppPurchase();
          if (callbackListenerID.current) {
            await Purchases.removeCustomerInfoUpdateListener({
              listenerToRemove: callbackListenerID.current,
            });
            callbackListenerID.current = undefined;
          }
        }

        if (import.meta.env.VITE_CI) {
          const customerInfo = await getFakeCustomerInfo();
          if (customerInfo) {
            await customerInfoUpdateListener(customerInfo);
          }
        }
      } catch (error) {
        console.error('Error initializing revenuecat', error);
      }
    },
    [customerInfoUpdateListener],
  );

  const subscribePremiumWithTrial = useCallback(async () => {
    const result = await subscribePremiumWithTrialApi();
    const { customerInfo } = result;
    setState(state => ({
      ...state,
      activeSubscription: getActiveSubscription(customerInfo),
      managementURL: customerInfo.managementURL ?? undefined,
    }));
    logAnalyticsEvent('trial_started');
    return result;
  }, []);

  const subscribePremiumWithoutTrial = useCallback(async () => {
    const result = await subscribePremiumWithoutTrialApi();
    const { customerInfo } = result;
    setState(state => ({
      ...state,
      activeSubscription: getActiveSubscription(customerInfo),
      managementURL: customerInfo.managementURL ?? undefined,
    }));
    return result;
  }, []);

  useEffect(() => {
    initialize(user);
  }, [initialize, user]);

  useEffect(() => {
    if (!isPremium) {
      setState(state => ({
        ...state,
        isOutOfTrial: user?.trial_ends_at
          ? dayjs(user?.trial_ends_at) < dayjs()
          : false,
      }));
    } else {
      setState(state => ({
        ...state,
        isOutOfTrial: false,
      }));
      setIsPaywallOpen(false);
    }
  }, [isPremium, user?.trial_ends_at]);

  useEffect(() => {
    if (state.isOutOfTrial && user?.onboarding_completed) {
      setIsPaywallOpen(true);
    }
  }, [state.isOutOfTrial, user?.onboarding_completed]);

  const showPaywall = () => {
    setIsPaywallOpen(true);
  };
  const hidePaywall = () => {
    setIsPaywallOpen(false);
  };

  return (
    <PaywallContext.Provider
      value={{
        isPremium,
        isInitialized:
          (state && user && state.userId === String(user.family_id)) ?? false,
        nbTrialDays: nbTrialDays.current,
        isOutOfTrial: state.isOutOfTrial,
        activeSubscription: state?.activeSubscription,
        managementURL: state?.managementURL,
        subscribePremiumWithTrial,
        subscribePremiumWithoutTrial,
        showPaywall,
        hidePaywall,
      }}
    >
      {children}
      <StartTrialPanel />
      <PaywallPanel
        isOpen={isPaywallOpen && !ignorePaywall(location)}
        onClose={() => setIsPaywallOpen(false)}
      />
    </PaywallContext.Provider>
  );
};

export const usePaywall = () => {
  const paywallContext = useContext(PaywallContext);
  if (!paywallContext) {
    throw new Error('usePaywall must be used within a PaywallContext');
  }
  return paywallContext;
};

export default PaywallProvider;
