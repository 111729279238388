export enum UserType {
  parent = 'parent',
  child = 'child',
}

export enum UserFamilyMemberType {
  mother = 'mother',
  father = 'father',
  boy = 'boy',
  girl = 'girl',
  other = 'other',
}

export interface User {
  id?: number;
  user_type: UserType;
  firstname?: string;
  lastname?: string;
  date_of_birth?: string;
  family_id?: number;
  school_level: string;
  school_id?: number;
  email?: string;
  phone_number?: string;
  family_member_type: UserFamilyMemberType;
  onboarding_completed: boolean;
  objectives: string[];
  created_at?: string;
  updated_at?: string;
  trial_started_at?: string;
  trial_ends_at?: string;
  xp: number;
}

export type Child = Omit<User, 'user_type'> & { user_type: UserType.child };
