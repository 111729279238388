import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';
import Button from 'src/components/common/designSystem/Button';
import { useCurrentUser } from 'src/queries/user';
import { useGenerateParentInvitationLink } from 'src/queries/invitations';
import { Share } from '@capacitor/share';
import { UserType } from 'src/types/user.types';

const AskParentButton = () => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const {
    isLoading: isGenerationInvitationLink,
    mutateAsync: generateInvitationLink,
  } = useGenerateParentInvitationLink();
  const { showError } = useNotification();

  if (!user || user.user_type !== UserType.child) {
    return null;
  }

  const onClick = async () => {
    try {
      if (isGenerationInvitationLink) {
        return;
      }
      const { link, isNewParent } = await generateInvitationLink();
      await Share.share({
        text: t(
          `invitations.parent.paywall.${isNewParent ? 'newParent' : 'existingParent'}`,
        ),
        url: link,
      });
    } catch (error: any) {
      console.error(error);
      showError({
        error,
        message: t('invitations.parent.error'),
      });
    }
  };

  return (
    <Button
      data-testid={'btn-ask-parent'}
      type={'default'}
      loading={isGenerationInvitationLink}
      onClick={onClick}
    >
      {t('invitations.parent.button')}
    </Button>
  );
};

export default AskParentButton;
