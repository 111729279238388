import { User } from 'src/types/user.types';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import SendIcon from 'src/images/send.svg?react';
import InvitationPanel from 'src/components/Invitation/InvitationPanel';
import { useState } from 'react';

const InviteChild = ({ child }: { child: User }) => {
  const { t } = useTranslation();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const inviteChild = () => {
    setIsInviteModalOpen(true);
  };

  return (
    <>
      <Button
        type={'default'}
        size={'middle'}
        className={'!bg-gray-200'}
        data-testid={'btn-invite-child'}
        onClick={inviteChild}
        icon={<SendIcon className={'fill-structural-darkest w-4 h-auto'} />}
      >
        {t('invitations.child.button', { firstName: child.firstname })}
      </Button>
      <InvitationPanel
        childId={child.id!}
        isOpen={isInviteModalOpen}
        onClose={() => {
          setIsInviteModalOpen(false);
        }}
      />
    </>
  );
};

export default InviteChild;
