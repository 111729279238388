import { useTranslation } from 'react-i18next';
import { useUpdateCurrentUser } from 'src/queries/user';
import { useNotification } from 'src/contexts/NotificationContext';
import PaywallScreenDescription from 'src/components/Paywall/PaywallScreenDescription';
import { logAnalyticsEvent } from 'src/modules/analytics';
import { useEffect } from 'react';

const OnboardingChildPaywallDescription = () => {
  const { t } = useTranslation();
  const { mutateAsync: saveCurrentUser, isLoading: isUpdating } =
    useUpdateCurrentUser();
  const { showError } = useNotification();

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen1_child',
    });
  }, []);

  const onContinue = async () => {
    try {
      await saveCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('trial_started');
      logAnalyticsEvent('onboarding_complete');
    } catch (error) {
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  return (
    <PaywallScreenDescription
      onContinue={onContinue}
      isContinuing={isUpdating}
    />
  );
};

export default OnboardingChildPaywallDescription;
