import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateUser } from 'src/queries/users';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import Spinner from 'src/components/common/designSystem/Spinner';
import RadioList from 'src/components/common/designSystem/RadioList';
import { UserFamilyMemberType } from 'src/types/user.types';

type ChildGenders =
  | UserFamilyMemberType.boy
  | UserFamilyMemberType.girl
  | UserFamilyMemberType.other;

const ChildGenderStep = ({
  childId,
  onFinish,
  isMe,
}: {
  childId: number;
  onFinish: () => void;
  isMe?: boolean;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const tNamespace = isMe
    ? 'onboarding.child.gender'
    : 'onboarding.parent.child.gender';

  const { mutateAsync: updateChild, isLoading: isUpdating } = useUpdateUser();
  const [selectedGender, setSelectedGender] = useState<ChildGenders>();

  const saveGender = async (gender: ChildGenders) => {
    try {
      setSelectedGender(gender);
      await updateChild({
        id: childId,
        family_member_type: gender,
      });
      onFinish();
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t(`${tNamespace}.fail`),
      });
    }
  };

  return (
    <div className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3">
      <OnboardingTitle title={t(`${tNamespace}.title`)} />
      <RadioList
        selected={selectedGender}
        onSelect={saveGender}
        options={[
          {
            label: t(`${tNamespace}.girl`),
            value: UserFamilyMemberType.girl,
            type: 'value',
          },
          {
            label: t(`${tNamespace}.boy`),
            value: UserFamilyMemberType.boy,
            type: 'value',
          },
          {
            label: t(`${tNamespace}.other`),
            value: UserFamilyMemberType.other,
            type: 'value',
          },
        ]}
      />
      <div className={'h-16'}>
        {isUpdating ? <Spinner size="large" /> : null}
      </div>
    </div>
  );
};

export default ChildGenderStep;
