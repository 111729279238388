import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';
import { requestOneSignalPermission } from 'src/utils/onesignal';
import Avatar from 'src/images/avatar.png';

const AskNotificationPanel = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const askNotification = async () => {
    await requestOneSignalPermission();
    onClose();
  };

  return (
    <AutoFloatingPanel isOpen={open} onClose={onClose}>
      <div className={'flex flex-col justify-center gap-12 p-4'}>
        <div className={'flex flex-col justify-center gap-2'}>
          <div className={'text-lg font-bold text-center text-balance'}>
            {t('onboarding.child.notification.title')}
          </div>
          <div className={'text-base text-center text-balance'}>
            {t('onboarding.child.notification.description')}
          </div>
        </div>
        <div
          className={
            'flex flex-row items-center rounded-xl bg-structural-lightest pr-2 py-2 relative mb-2'
          }
        >
          <div
            className={
              'absolute -bottom-2 bg-structural-lighter rounded-b-xl h-2 w-10/12 left-1/2 transform -translate-x-1/2'
            }
          />
          <img className={'w-16'} src={Avatar} alt={'Avatar'} />
          <div className={'flex flex-col flex-1 overflow-hidden'}>
            <div className={'flex flex-row justify-between gap-4 items-center'}>
              <div
                className={
                  'font-bold text-base text-ellipsis overflow-hidden whitespace-nowrap'
                }
              >
                {t('onboarding.child.notification.card.title')}
              </div>
              <div className={'text-sm text-balance text-structural-neutral'}>
                {t('onboarding.child.notification.card.now')}
              </div>
            </div>
            <div className={'text-sm text-balance'}>
              {t('onboarding.child.notification.card.description')}
            </div>
          </div>
        </div>
        <div className={'flex flex-col justify-center gap-2'}>
          <Button
            className={'w-full'}
            type={'primary'}
            data-testid={'btn-activate-notification'}
            onClick={askNotification}
          >
            {t('onboarding.child.notification.button')}
          </Button>
          <Button
            className={'w-full'}
            type={'text'}
            data-testid={'btn-skip'}
            onClick={onClose}
          >
            {t('onboarding.skip')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default AskNotificationPanel;
