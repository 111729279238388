import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetLesson, useStartLessonAnalysis } from 'src/queries/lessons';
import { PieceType, useCreateLessonPiece } from 'src/queries/lesson_pieces';
import PhotoContextProvider from 'src/components/Photo/PhotoContext';
import PhotoPreview from 'src/components/Photo/PhotoPreview';
import PhotoHeader from 'src/components/Photo/PhotoHeader';
import PhotoFooter from 'src/components/Photo/PhotoFooter';
import BlankLayout from 'src/layouts/BlankLayout';
import { projectColors } from 'src/styles/project-styles';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import AnotherPhotoPanel from 'src/components/Photo/AnotherPhotoPanel';
import { logAnalyticsEvent } from 'src/modules/analytics';

const LessonAddPhoto = () => {
  const { lessonId } = useParams();
  const { t } = useTranslation();
  const { data: lesson } = useGetLesson(lessonId);
  const { mutateAsync: createLessonPiece } = useCreateLessonPiece({
    type: PieceType.PHOTO,
  });
  const { mutateAsync: startAnalysis } = useStartLessonAnalysis();

  const handleCreatePiece = async (photo: Blob, uri?: string) => {
    if (!lesson || !lesson.id) {
      return;
    }

    const piece = await createLessonPiece({
      lessonId: lesson.id,
      title: t('lessons.lessonPieceNumbered', {
        number: lesson.lesson_pieces.length + 1,
      }),
      file: photo,
      uri,
    });
    logAnalyticsEvent('add_lesson-content', {
      lessonId: lesson.id,
      lessonPieceId: piece.id,
      lessonSubject: lesson.subject_matter,
      lessonTitle: lesson.title,
    });
    await startAnalysis(lesson.id);
  };

  return (
    <PhotoContextProvider onPhotoReady={handleCreatePiece}>
      <BlankLayout
        appBackground={'bg-transparent'}
        statusBar={{
          color: projectColors.primary.darkest,
          style: Style.Dark,
        }}
        appBackgroundFooter={'bg-primary-darkest'}
      >
        <div className="w-full h-full flex flex-col justify-between text-white overflow-hidden">
          <PhotoHeader />
          <PhotoPreview />
          <PhotoFooter />
        </div>
        <AnotherPhotoPanel
          lessonId={Number(lessonId)}
          hasEvaluationDate={Boolean(lesson?.evaluation_date)}
        />
      </BlankLayout>
    </PhotoContextProvider>
  );
};

export default LessonAddPhoto;
