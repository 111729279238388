import {
  getInLocalStorageByKey,
  setInLocalStorage,
} from 'src/utils/storageManager';
import { LocalStorageKey } from 'src/utils/constants';
import { queryClient } from 'src/queries/base';
import { getLocalStorageUserID } from 'src/utils/usersUtils';

export const hasDoneOnboardingFirstLesson = (): boolean => {
  const userId = getLocalStorageUserID();
  if (!userId) {
    return false;
  }
  const localStorageHasDoneOnboardingFirstLesson = getInLocalStorageByKey(
    LocalStorageKey.HAS_DONE_ONBOARDING_FIRST_LESSON,
  );
  return Boolean(
    localStorageHasDoneOnboardingFirstLesson &&
      localStorageHasDoneOnboardingFirstLesson[userId],
  );
};

export const setHasDoneOnboardingFirstLesson = async () => {
  const userId = getLocalStorageUserID();
  if (!userId) {
    return;
  }
  const localStorageHasDoneOnboardingFirstLesson = getInLocalStorageByKey(
    LocalStorageKey.HAS_DONE_ONBOARDING_FIRST_LESSON,
  );
  if (
    localStorageHasDoneOnboardingFirstLesson &&
    localStorageHasDoneOnboardingFirstLesson[userId]
  ) {
    return;
  }
  setInLocalStorage(LocalStorageKey.HAS_DONE_ONBOARDING_FIRST_LESSON, {
    ...(localStorageHasDoneOnboardingFirstLesson || {}),
    [userId]: true,
  });
  await queryClient.resetQueries(['milestones']);
  await queryClient.refetchQueries(['milestones']);
};
