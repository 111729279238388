import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PaywallScreenDescription from 'src/components/Paywall/PaywallScreenDescription';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics';

const OnboardingParentChildPaywallDescription = () => {
  const { childId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen1_parent',
    });
  }, []);

  return (
    <PaywallScreenDescription
      onContinue={() => {
        navigate(
          `/onboarding/parent/child/${childId}/school_level/device/paywall/pay?${searchParams.toString()}`,
        );
      }}
    />
  );
};

export default OnboardingParentChildPaywallDescription;
