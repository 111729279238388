import { NativeAudio } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';

export const preloadSoundAsset = ({
  filename,
  assetId,
}: {
  filename: string;
  assetId: string;
}) => {
  if (Capacitor.isNativePlatform()) {
    return NativeAudio.preload({
      assetId,
      audioChannelNum: 1,
      assetPath: `public/sounds/${filename}`,
      isUrl: false,
    });
  }
  return NativeAudio.preload({
    assetId,
    audioChannelNum: 1,
    assetPath: '/public/sounds/success.mp3',
    isUrl: true,
  });
};
