import { useRef, useEffect, useState } from 'react';
import {
  useGetUnreadNotifications,
  useReadNotification,
} from 'src/queries/notifications';
import InAppLessonPieceProcessFailedNotifications from 'src/components/Notifications/InAppLessonPieceProcessFailedNotification';
import InAppQuizReadyNotification from 'src/components/Notifications/InAppQuizReadyNotification';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';

const InAppNotifications = ({
  allowedNotifications,
}: {
  allowedNotifications?: InAppNotificationType[];
}) => {
  const { data: notifications } = useGetUnreadNotifications({
    refetchInterval: 5000,
  });
  const { mutateAsync: readNotification } = useReadNotification();
  const [currentNotification, setCurrentNotification] =
    useState<InAppNotification>();
  const readNotifications = useRef(new Set<number>());

  useEffect(() => {
    if (
      currentNotification ||
      !notifications ||
      !notifications.pages.length ||
      !notifications.pages[0].items.length
    ) {
      return;
    }
    if (allowedNotifications) {
      const allowedNotification = notifications.pages[0].items.find(
        notification =>
          allowedNotifications.includes(notification.type) &&
          !notification.shown_at &&
          !readNotifications.current.has(notification.id),
      );
      if (allowedNotification) {
        setCurrentNotification(allowedNotification);
        return;
      }
    }
    const unreadNotification = notifications.pages[0].items.find(
      notification =>
        !notification.shown_at &&
        !readNotifications.current.has(notification.id),
    );
    setCurrentNotification(unreadNotification);
  }, [allowedNotifications, currentNotification, notifications]);

  useEffect(() => {
    if (currentNotification) {
      if (
        notifications &&
        notifications.pages.length &&
        notifications.pages[0].items.find(
          notification => notification.id === currentNotification.id,
        )
      ) {
        return;
      }
      setCurrentNotification(undefined);
    }
  }, [currentNotification, notifications]);

  const handleNotificationRead = async () => {
    if (currentNotification) {
      readNotifications.current.add(currentNotification.id);
      await readNotification(currentNotification.id);
    }
  };

  if (!currentNotification) {
    return null;
  }

  switch (currentNotification.type) {
    case InAppNotificationType.LESSON_PIECE_PROCESS_FAIL:
      return (
        <InAppLessonPieceProcessFailedNotifications
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    case InAppNotificationType.QUIZ_READY:
      return (
        <InAppQuizReadyNotification
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    default:
      return null;
  }
};

export default InAppNotifications;
