export default {
  title: '{{firstName}} te lance un défi !',
  you: 'Toi',
  start: 'Démarrer le défi !',
  result: {
    signup: 'Pour voir ton score, inscris-toi !',
    title: {
      success: 'Bravo {{firstName}} !',
      failure: 'Retente ta chance !',
      equal: 'Égalité !',
    },
    subtitle: {
      success: 'Tu as gagné',
      failure: 'Tu as perdu',
      equal: 'C’est une égalité',
    },
  },
  error: {
    challengingHimself: 'Tu ne peux pas te défier toi même ! 😅',
  },
};
