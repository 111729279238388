import { useTranslation } from 'react-i18next';

import { getSubjectInfo } from 'src/utils/subject';
import { LessonSubject } from 'src/types/lesson.types';

export default function SubjectCard({
  subject,
  className,
  onClick,
}: {
  subject: LessonSubject;
  className?: string;
  onClick?: () => void;
}) {
  const { t } = useTranslation();
  const selectedSubject = getSubjectInfo(subject);

  if (!selectedSubject) return null;

  return (
    <div
      className={
        'flex flex-[0_0_38%] flex-col gap-2 items-center justify-center cursor-pointer'
      }
      data-testid={`card-${subject}`}
      onClick={onClick}
    >
      <div
        className={
          'rounded-xl flex items-center justify-center p-4 w-full h-24 bg-primary-lighter relative ' +
          className
        }
      >
        <selectedSubject.IconComponent className="max-w-full max-h-full" />
        {selectedSubject.isBeta && (
          <div className="absolute top-2 left-2 rounded-lg bg-primary-darkest text-white text-xs font-bold p-1">
            Beta
          </div>
        )}
      </div>
      <div className="font-bold text-structural-darker text-center capitalize">
        {t(selectedSubject.labelKey)}
      </div>
    </div>
  );
}
