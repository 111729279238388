import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { authErrorCodeToMessage } from 'src/modules/firebase';
import type { AuthError } from 'firebase/auth';
import { Form } from 'antd';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import SetPasswordInputs from 'src/components/Login/SetPasswordInputs';

const ResetPasswordSetPassword = ({ oobCode }: { oobCode: string }) => {
  const { t } = useTranslation();
  const [resettingPassword, setResettingPassword] = useState(false);
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotification();

  const resetPassword = async ({ password }: { password: string }) => {
    setResettingPassword(true);
    try {
      await FirebaseAuthentication.confirmPasswordReset({
        oobCode,
        newPassword: password,
      });
      showSuccess({
        message: t('login.resetPassword.passwordResetSuccess'),
      });
    } catch (error) {
      console.error(error);
      showError({
        message: authErrorCodeToMessage(error as AuthError),
        error,
      });
    } finally {
      navigate('/');
      setResettingPassword(false);
    }
  };

  return (
    <div className="flex flex-col items-center h-full">
      <div className={'font-extrabold text-base text-balance text-center'}>
        {t('login.resetPassword.title')}
      </div>
      <Form
        name="resetPasswordForm"
        onFinish={resetPassword}
        className="flex-1 bg-transparent w-full flex flex-col justify-center items-center px-4 gap-4"
        disabled={resettingPassword}
        validateTrigger="onSubmit"
        layout="vertical"
      >
        <SetPasswordInputs />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="resetPasswordForm"
          type="primary"
          htmlType="submit"
          loading={resettingPassword}
        >
          {t('login.resetPassword.resetMyPassword')}
        </Button>
      </Footer>
    </div>
  );
};

export default ResetPasswordSetPassword;
