import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { isTestFlight } from 'src/modules/ios';
import { LatestVersion } from '@capgo/capacitor-updater/dist/esm/definitions';

const UPDATE_URL_PROD = 'https://app.baobab-labs.com/api/app_versions/latest';
const UPDATE_URL_STAGING =
  'https://staging-app.baobab-labs.com/api/app_versions/latest';

export const initAutoUpdater = async (): Promise<{
  shouldUpdateNativeApp: boolean;
}> => {
  if (import.meta.env.DEV || !Capacitor.isNativePlatform()) {
    return {
      shouldUpdateNativeApp: false,
    };
  }

  try {
    if (await isTestFlight()) {
      await CapacitorUpdater.setUpdateUrl({
        url: UPDATE_URL_STAGING,
      });
    } else {
      await CapacitorUpdater.setUpdateUrl({
        url: UPDATE_URL_PROD,
      });
    }

    const [current, latest] = await Promise.all([
      CapacitorUpdater.current(),
      CapacitorUpdater.getLatest(),
    ]);

    const latestWithData = latest as LatestVersion & {
      data: { latest: string };
    };
    const shouldUpdateNativeApp =
      latestWithData.version !== latestWithData.data.latest;

    const reloadWebBundle =
      current.bundle.version !== latest.version &&
      latest.version !== APP_VERSION;

    if (!reloadWebBundle || !latest.url) {
      return {
        shouldUpdateNativeApp,
      };
    }

    const version = await CapacitorUpdater.download({
      url: latest.url,
      version: latest.version,
    });
    await CapacitorUpdater.set(version);
    await CapacitorUpdater.reload();

    return {
      shouldUpdateNativeApp,
    };
  } catch (error) {
    console.error('Error while updating', error);
  }

  return {
    shouldUpdateNativeApp: false,
  };
};
