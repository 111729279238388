import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhotoStatus, usePhoto } from 'src/components/Photo/PhotoContext';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';
import { useStartLessonAnalysis } from 'src/queries/lessons';
import LessonPieceProcessingPanel from 'src/components/Piece/LessonPieceProcessingPanel';
import { useNotification } from 'src/contexts/NotificationContext';
import { CameraPreview } from '@capgo/camera-preview';
import AddLessonEvaluationDate from 'src/components/AddLesson/AddLessonEvaluationDate';

const AnotherPhotoPanel = ({
  lessonId,
  hasEvaluationDate,
}: {
  lessonId: number;
  hasEvaluationDate: boolean;
}) => {
  const { status, clearPhoto } = usePhoto();
  const { t } = useTranslation();
  const { mutateAsync: startAnalysis, isLoading } = useStartLessonAnalysis();
  const [showEvaluationDate, setShowEvaluationDate] = useState(false);
  const [showEndingPanel, setShowEndingPanel] = useState(false);
  const { showError } = useNotification();

  const handleNo = async () => {
    try {
      await startAnalysis(lessonId);
      await CameraPreview.stop();
      if (!hasEvaluationDate) {
        setShowEvaluationDate(true);
        return;
      }
      setShowEndingPanel(true);
    } catch (error) {
      console.error(error);
      showError({
        message: t('lessons.photo.error'),
        error: error,
      });
    }
  };

  const onCloseEvaluationDate = () => {
    setShowEvaluationDate(false);
    setShowEndingPanel(true);
  };

  const handleYes = () => {
    clearPhoto();
  };

  return (
    <>
      <AutoFloatingPanel
        isOpen={status === PhotoStatus.PHOTO_SENT && !showEndingPanel}
      >
        <div
          className={
            'flex flex-col gap-4 p-8 items-center justify-center text-structural-darkest text-center'
          }
        >
          <div className={'text-5xl'}>📷</div>
          <div className={'text-xl font-bold text-balance'}>
            {t('lessons.photo.another.title')}
          </div>
          <div className={'text-base text-balance'}>
            {t('lessons.photo.another.description')}
          </div>
          <div className={'flex flex-row gap-4 w-full'}>
            <Button
              onClick={handleYes}
              disabled={isLoading}
              data-testid={'btn-another-photo'}
              type={'default'}
            >
              {t('global.yes')}
            </Button>
            <Button
              onClick={handleNo}
              loading={isLoading}
              data-testid={'btn-noother-photo'}
              type={'default'}
            >
              {t('global.no')}
            </Button>
          </div>
        </div>
      </AutoFloatingPanel>
      <AddLessonEvaluationDate
        isOpen={showEvaluationDate}
        onClose={onCloseEvaluationDate}
        lessonId={lessonId}
      />
      <LessonPieceProcessingPanel isOpen={showEndingPanel} />
    </>
  );
};

export default AnotherPhotoPanel;
