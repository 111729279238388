export default {
  title: 'Profitez de l’expérience Baobab <1>sans limite !</1>',
  tag: 'abonnement',
  item1: {
    title: 'C’est magique',
    description:
      'Des révisions sur-mesure, parfaitement alignées avec les cours de l’enseignant.',
  },
  item2: {
    title: 'Duels entre amis',
    description: 'Votre enfant apprend en défiant ses amis !',
  },
  item3: {
    title: 'Parents rassurés',
    description: 'Gagnant, gagnant !',
  },
  button: 'Commencer mon offre d’essai',
  description1: '2 semaines d’essai puis 7.99€/mois',
  description2: 'Abonnement valable pour 2 enfants',
  footer:
    "2 semaines d'essai gratuits, annulables à tout moment. Après l'essai, 7,99 €/mois, renouvellement automatique sauf annulation via les paramètres du compte.",
  error: 'Une erreur est survenue. Merci de réessayer.',
  panel: {
    title: 'Abonnement',
    type: 'Mensuel',
    price: '7.99€',
    time: 'par mois',
    footer: 'Annulable depuis les paramètres du compte',
    cancel: 'Annuler mon abonnement',
    nextBilling: 'Prochain prélèvement le {{date}}',
    since: 'Abonnement actif depuis le {{date}}',
  },
  locked: {
    title: 'Pour continuer l’expérience, abonnez-vous :',
    description: 'Votre offre d’essai a expiré',
    footer:
      'Renouvellement automatique de 7,99€/mois sauf annulation via les paramètres du compte.',
  },
  description: {
    parent: {
      title: 'La magie de Baobab <1>en illimité !</1>',
      subtitle: '<0>{{nbTrialDays}} jours d’essai gratuit</0>',
      subtitle2:
        'Les devoirs deviennent un moment fun et sans stress, validé par les enfants !',
      item1: {
        title: 'C’est magique',
        description:
          'Des révisions sur-mesure, parfaitement alignées avec les cours de l’enseignant.',
      },
      item2: {
        title: 'C’est fun',
        description:
          'Un apprentissage ludique qui motive les enfants, fini les prises de tête !',
      },
      item3: {
        title: 'C’est rassurant',
        description:
          'Pour les enfants comme pour les parents, tranquillité d’esprit garantie.',
      },
      button: 'Commencer mon essai gratuit',
    },
    child: {
      title: 'La magie de Baobab <1>en illimité !</1>',
      subtitle: '<0>{{nbTrialDays}} jours d’essai gratuit</0>',
      subtitle2: 'Faire ses devoirs n’a jamais été aussi stylé !',
      item1: {
        title: 'C’est efficace',
        description: 'Tu révises ce qui va tomber à l’interro ! Génial non?',
      },
      item2: {
        title: 'C’est fun',
        description: 'Tu gagnes des XP en révisant... le rêve!',
      },
      item3: {
        title: 'C’est stylé',
        description: 'Tu connais ton cours... Tes parents sont relax !',
      },
      button: 'Commencer mon essai gratuit',
    },
  },
  pay: {
    title: '<0>Essai gratuit</0> de Baobab',
    item1: '{{nbTrialDays}} jours gratuits, annulez à tout moment',
    item2: 'Vous recevrez un rappel avant la fin de votre période d’essai.',
    paymentDate: 'Dû le {{date}}',
    paymentToday: 'Dû aujourd’hui <1>({{nbTrialDays}} jours gratuits)</1>',
    button: 'Commencer l’essai gratuit et s’abonner',
    buttonSkip: 'Je m’abonne plus tard',
    footer:
      'Annulez à tout moment. L’abonnement se renouvelle automatiquement. En vous abonnant, vous acceptez notre <2>politique de confidentialité</2> et nos <5>conditions générales d’utilisation.</5>',
  },
};
