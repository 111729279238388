import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

export const initSentry = () => {
  Sentry.init(
    {
      dsn: 'https://58a93481b17ea401d177a22918387a09@o4506868795768832.ingest.us.sentry.io/4506868798259200',
      dist: process.env.npm_package_version,
      integrations: [
        SentryReact.reactRouterV6BrowserTracingIntegration({
          useEffect: useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        SentryReact.replayIntegration({
          networkDetailAllowUrls: ['/api'],
          maskAllText: false,
          blockAllMedia: false,
        }),
        SentryReact.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      ignoreErrors: [
        /Request failed with status code 40[014]/,
        'Failed to fetch dynamically imported module',
        'Abort due to cancellation of share.',
        'AbortError: Share canceled',
        'Request timeout after',
      ],
      beforeSend(event) {
        const isFromAmplitude = event.exception?.values?.find(exception => {
          if (
            exception.stacktrace?.frames?.find(frame =>
              frame.module?.match('@amplitude'),
            )
          )
            return true;
          return false;
        });
        if (isFromAmplitude) return null;
        return event;
      },
      // Percentage of sessions to be saved. Set as 0 because we have clarity
      replaysSessionSampleRate: 0,
      // percentage of sessions to be saved when there is an error. Here 100% to have it on sentry
      replaysOnErrorSampleRate: 0.8,
      tracesSampleRate: 0.5,
      environment: import.meta.env.MODE,
    },
    SentryReact.init,
  );
  (async () => {
    Sentry.setTag(
      'app_version',
      (await CapacitorUpdater.current()).bundle.version || 'web',
    );
  })();
};
