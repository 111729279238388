import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateUser } from 'src/queries/users';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import Spinner from 'src/components/common/designSystem/Spinner';
import RadioList from 'src/components/common/designSystem/RadioList';

const ChildSchoolLevelStep = ({
  childId,
  onFinish,
  isMe,
}: {
  childId: number;
  onFinish: () => void;
  isMe?: boolean;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const tNamespace = isMe
    ? 'onboarding.child.school_level'
    : 'onboarding.parent.child.school_level';

  const { mutateAsync: updateChild, isLoading: isUpdating } = useUpdateUser();
  const [selectedLevel, setSelectedLevel] = useState<string>();

  const save = async (level: string) => {
    try {
      setSelectedLevel(level);
      await updateChild({
        id: childId,
        school_level: level,
      });
      onFinish();
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t(`${tNamespace}.fail`),
      });
    }
  };

  return (
    <div className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3">
      <OnboardingTitle title={t(`${tNamespace}.title`)} />
      <RadioList
        selected={selectedLevel}
        onSelect={save}
        options={[
          {
            type: 'value',
            value: '6ème',
            label: t('users.schoolLevel6'),
          },
          {
            type: 'value',
            value: '5ème',
            label: t('users.schoolLevel5'),
          },
          {
            type: 'value',
            value: '4ème',
            label: t('users.schoolLevel4'),
          },
          {
            type: 'value',
            value: '3ème',
            label: t('users.schoolLevel3'),
          },
          { type: 'divider' },
          {
            type: 'value',
            value: 'other',
            label: t('global.other'),
          },
        ]}
      />
      <div className={'h-16'}>
        {isUpdating ? <Spinner size="large" /> : null}
      </div>
    </div>
  );
};

export default ChildSchoolLevelStep;
