import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useGenerateInvitationLink } from 'src/queries/invitations';
import { Share } from '@capacitor/share';
import { useNotification } from 'src/contexts/NotificationContext';
import { Clipboard } from '@capacitor/clipboard';
import { QRCode } from 'antd';
import Button from 'src/components/common/designSystem/Button';
import { logAnalyticsEvent } from 'src/modules/analytics';
import { useCurrentUser } from 'src/queries/user';
import { isCancelError } from 'src/utils/share';

const ParentChildOtherDevice = ({
  childId,
  onFinish,
}: {
  childId: number;
  onFinish: () => void;
}) => {
  const { t } = useTranslation();

  const [otherDeviceLink, setOtherDeviceLink] = useState<string>();
  const [canShare, setCanShare] = useState<boolean>(false);
  const { data: currentUser } = useCurrentUser();
  const {
    mutateAsync: generateInvitationLink,
    isLoading: generatingOtherDeviceLink,
  } = useGenerateInvitationLink();
  const { showError } = useNotification();

  const generateOtherDeviceLink = useCallback(async () => {
    if (otherDeviceLink || generatingOtherDeviceLink) {
      return;
    }
    try {
      await generateInvitationLink(childId, {
        onSuccess(generatedLink) {
          setOtherDeviceLink(generatedLink);
        },
      });
    } catch (error) {
      console.error(error);
      showError({
        message: t('onboarding.parent.child.device.share.error'),
        error,
      });
    }
  }, [
    childId,
    generateInvitationLink,
    generatingOtherDeviceLink,
    otherDeviceLink,
    showError,
    t,
  ]);

  useEffect(() => {
    generateOtherDeviceLink();
  }, [generateOtherDeviceLink]);

  const checkCanShare = async () => {
    setCanShare((await Share.canShare()).value);
  };

  useEffect(() => {
    checkCanShare();
  }, []);

  const { showSuccess } = useNotification();

  const handleCopy = async () => {
    await Clipboard.write({
      string: otherDeviceLink,
    });
    showSuccess({
      message: t('onboarding.parent.child.device.share.copied'),
    });
  };
  const handleShare = async () => {
    try {
      const { activityType } = await Share.share({
        title: t('onboarding.parent.child.device.share.description'),
        text: t('onboarding.parent.child.device.share.description'),
        url: otherDeviceLink,
      });
      logAnalyticsEvent('invite_user', {
        from_user: currentUser?.id,
        to_user: childId,
        activity_type: activityType,
      });
    } catch (error) {
      if (isCancelError(error)) {
        return;
      }

      console.error(error);
    }
  };

  if (generatingOtherDeviceLink) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  if (!otherDeviceLink) {
    return null;
  }

  return (
    <div
      data-testid={'other-device'}
      className="flex-1 flex flex-col items-center justify-between gap-6 w-full h-full p-6"
    >
      <div className="text-center text-balance font-semibold text-base">
        {t('invitations.modalShareTitle')}
      </div>
      <QRCode
        bordered={false}
        size={96}
        value={otherDeviceLink}
        status={generatingOtherDeviceLink ? 'loading' : 'active'}
      />
      <div
        className="p-3 bg-white flex items-center gap-2 justify-between rounded-xl w-full"
        onClick={handleCopy}
      >
        <span className={'text-ellipsis whitespace-nowrap overflow-hidden'}>
          {otherDeviceLink}
        </span>
        <div className={'w-5'}>
          <IconMdiContentCopy className="text-primary-darkest text-xl w-5" />
        </div>
      </div>
      <div className={'flex w-full flex-col gap-2'}>
        {canShare ? (
          <Button data-testid="btn-share" type="primary" onClick={handleShare}>
            {t('global.share')}
          </Button>
        ) : (
          <Button data-testid="btn-share" type="primary" onClick={handleCopy}>
            {t('global.copyLink')}
          </Button>
        )}
        <Button data-testid="btn-next" type="text" onClick={onFinish}>
          {t('global.end')}
        </Button>
      </div>
    </div>
  );
};

export default ParentChildOtherDevice;
