import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import QuizReadyNotificationCard from 'src/components/Notifications/NotificationCard/QuizReadyNotificationCard';
import ChallengeFinishedNotificationCard from 'src/components/Notifications/NotificationCard/ChallengeFinishedNotificationCard';

const Index = ({ notification }: { notification: InAppNotification }) => {
  switch (notification.type) {
    case InAppNotificationType.QUIZ_READY:
      return <QuizReadyNotificationCard notification={notification} />;
    case InAppNotificationType.CHALLENGE_FINISHED:
      return <ChallengeFinishedNotificationCard notification={notification} />;
    default:
      return null;
  }
};

export default Index;
