import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import TransparentModal from 'src/components/common/designSystem/TransparentModal';
import useMilestones from 'src/components/Milestones/useMilestones';
import MilestoneCard from 'src/components/Milestones/MilestoneCard';
import { MilestoneType } from 'src/types/milestone.types';

const allMilestones: MilestoneType[] = Object.values(MilestoneType);

const MilestoneModal = ({
  trackedMilestones = allMilestones,
}: {
  trackedMilestones?: MilestoneType[];
}) => {
  const { t } = useTranslation();
  const { milestoneToShow, onClose } = useMilestones({
    trackedMilestones,
  });

  if (!milestoneToShow) return null;

  return (
    <TransparentModal
      isOpen={Boolean(milestoneToShow)}
      onClose={onClose}
      data-testid={'modal-streak'}
      footer={
        <Button
          type={'primary'}
          data-testid={'btn-milestone-continue'}
          onClick={onClose}
        >
          {milestoneToShow.type === MilestoneType.daily_streaks
            ? t('milestones.streak.continue')
            : t('milestones.continue')}
        </Button>
      }
    >
      <MilestoneCard
        type={milestoneToShow.type}
        count={milestoneToShow.count}
      />
    </TransparentModal>
  );
};

export default MilestoneModal;
