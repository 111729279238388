import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormOutlined, MoreOutlined } from '@ant-design/icons';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { List } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import LessonDeleteMenuItem from 'src/components/LessonDetail/LessonDeleteMenuItem';

const LessonHeaderMenu = ({ lessonId }: { lessonId: number }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <MoreOutlined
        className={'text-2xl'}
        onClick={() => {
          setIsMenuOpen(true);
        }}
      />
      <AutoFloatingPanel isOpen={isMenuOpen} onClose={onClose}>
        <List>
          <List.Item
            data-testid="btn-rename-lesson"
            onClick={() => {
              setIsMenuOpen(false);
              navigate(`/lessons/${lessonId}/rename`);
            }}
            arrow={false}
          >
            <div className="flex gap-2 items-center">
              <FormOutlined />
              <span>{t('lessons.menu.rename')}</span>
            </div>
          </List.Item>
          <LessonDeleteMenuItem />
        </List>
      </AutoFloatingPanel>
    </>
  );
};

export default LessonHeaderMenu;
