import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUpdateCurrentUser } from 'src/queries/user';
import { useSwitchUser } from 'src/queries/sessions';
import { useNotification } from 'src/contexts/NotificationContext';
import { logAnalyticsEvent } from 'src/modules/analytics';

const useEndOnboardingParentAndSwitchToChildOnboarding = ({
  childId,
}: {
  childId: number;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    mutateAsync: updateCurrentUser,
    isLoading: finishingParentOnboarding,
  } = useUpdateCurrentUser();
  const { mutateAsync: switchUser, isLoading: switching } = useSwitchUser();
  const [searchParams] = useSearchParams();
  const { showError } = useNotification();

  const endOnboarding = useCallback(async () => {
    try {
      await updateCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('onboarding_complete');
      await switchUser(childId);
      navigate(
        `/onboarding/child/objective?showParentPanel=true&${searchParams.toString()}`,
      );
    } catch (error) {
      console.error(error);
      showError({
        message: t('error.http.default'),
        error,
      });
    }
  }, [
    updateCurrentUser,
    switchUser,
    childId,
    navigate,
    searchParams,
    showError,
    t,
  ]);

  return { endOnboarding, isEnding: finishingParentOnboarding || switching };
};

export default useEndOnboardingParentAndSwitchToChildOnboarding;
