import { useTranslation } from 'react-i18next';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { subjects } from 'src/utils/constants';
import Button from 'src/components/common/designSystem/Button';
import { useNavigate } from 'react-router-dom';

const QuizReadyNotificationCard = ({
  notification,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.QUIZ_READY;
  };
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const subject = subjects[notification.params.lesson_subject];

  return (
    <div
      data-testid={`notification-quiz-ready-${notification.params.lesson_id}`}
      className={'flex flex-row bg-white rounded-xl p-4 gap-4'}
      onClick={() => {
        navigate(
          `/lessons/${notification.params.lesson_id}/quizzes/${notification.params.quiz_id}`,
        );
      }}
    >
      {subject ? (
        <div>
          <div
            className={
              'w-12 h-12 bg-primary-neutral rounded-2xl flex items-center justify-center'
            }
          >
            <subject.IconComponent className={'w-8 h-8'} />
          </div>
        </div>
      ) : null}
      <div className={'flex flex-col w-full'}>
        <div
          className={'text-base font-bold text-balance text-structural-darkest'}
        >
          {t('notifications.quiz_ready.title')}
        </div>
        <div className={'flex flex-row justify-between items-center'}>
          <div
            className={
              'font-semibold line-clamp-2 text-ellipsis overflow-hidden text-balance text-structural-neutral'
            }
          >
            {notification.params.lesson_title}
          </div>
          <Button type={'default'} size={'small'}>
            {t('notifications.quiz_ready.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuizReadyNotificationCard;
