import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { Share } from '@capacitor/share';
import { useCreateQuizChallenge } from 'src/queries/quiz';
import { useNotification } from 'src/contexts/NotificationContext';
import { logAnalyticsEvent } from 'src/modules/analytics';
import { Lesson } from 'src/types/lesson.types';
import { isCancelError } from 'src/utils/share';

const QuizShareButton = ({
  quizId,
  lesson,
}: {
  quizId: number;
  lesson: Lesson;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: createChallenge, isLoading } =
    useCreateQuizChallenge(quizId);
  const { showError } = useNotification();

  const handleShare = async () => {
    try {
      const { link } = await createChallenge();
      const { activityType } = await Share.share({
        title: t('quiz.result.share.message.title', {
          lessonTitle: lesson.title,
        }),
        text: t('quiz.result.share.message.description', {
          lessonTitle: lesson.title,
        }),
        url: link,
      });
      logAnalyticsEvent('challenge_friend', {
        activity_type: activityType,
      });
    } catch (error) {
      if (isCancelError(error)) {
        return;
      }
      console.error(error);
      showError({
        message: t('quiz.result.share.error'),
        error,
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        data-testid="btn-share-quiz"
        loading={isLoading}
        onClick={handleShare}
      >
        {t('quiz.result.share.button')}
      </Button>
    </>
  );
};

export default QuizShareButton;
