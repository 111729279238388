import PaywallCheckIcon from 'src/components/Paywall/PaywallCheckIcon';

function RadioList<ValueType extends string | number>({
  options,
  selected,
  onSelect,
}: {
  options: Array<
    { type: 'value'; label: string; value: ValueType } | { type: 'divider' }
  >;
  selected?: ValueType;
  onSelect: (value: ValueType) => void;
}) {
  return (
    <div className="flex-1 flex flex-col gap-2 justify-center w-full">
      {options.map((option, index) => {
        if (option.type === 'divider') {
          return (
            <div
              key={index}
              className={'w-full flex justify-center items-center'}
            >
              <div className="h-0.5 w-5 bg-black bg-opacity-20 my-2" />
            </div>
          );
        }
        if (option.type !== 'value') {
          return null;
        }
        const isSelected = selected === option.value;
        return (
          <div
            key={option.value}
            data-testid={`option-${option.value}`}
            onClick={() => {
              onSelect(option.value);
            }}
            className={`w-full items-center ${isSelected ? 'bg-gradient-to-r' : 'bg-[#F5F5F5]'} from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-0.5`}
          >
            <div
              className={`p-3 flex rounded-[10px] bg-[#F5F5F5] items-center justify-between text-base font-extrabold w-full`}
            >
              <div>{option.label}</div>
              {isSelected ? <PaywallCheckIcon /> : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RadioList;
