export default {
  ok: 'OK',
  add: 'Ajouter',
  save: 'Sauvegarder',
  continue: 'Continuer',
  share: 'Partager',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  end: 'Terminer',
  restart: 'Recommencer',
  next: 'Suivant',
  notFoundError: "Cette page n'existe pas",
  goToHome: "Aller à l'accueil",
  or: 'ou',
  back: 'Retour',
  validation: {
    fieldRequired: 'Ce champ est obligatoire',
  },
  copyLink: 'Copier le lien',
  letsGo: "C'est parti !",
  yes: 'Oui',
  no: 'Non',
  other: 'Autre',
};
