import { LessonSubject } from 'src/types/lesson.types';

export enum InAppNotificationType {
  QUIZ_READY = 'QUIZ_READY',
  LESSON_PIECE_PROCESS_FAIL = 'LESSON_PIECE_PROCESS_FAIL',
  CHALLENGE_FINISHED = 'CHALLENGE_FINISHED',
}

export type QuizReadyInAppNotification = {
  type: InAppNotificationType.QUIZ_READY;
  params: {
    lesson_id: number;
    lesson_title: string;
    lesson_subject: LessonSubject;
    lesson_piece_id: number;
    quiz_id: number;
  };
};
export type LessonPieceProcessFailInAppNotification = {
  type: InAppNotificationType.LESSON_PIECE_PROCESS_FAIL;
  params: {
    lesson_id: number;
    lesson_title: string;
    lesson_subject: LessonSubject;
    lesson_piece_id: number;
  };
};
export type ChallengeFinishedInAppNotification = {
  type: InAppNotificationType.CHALLENGE_FINISHED;
  params: {
    lesson_id: number;
    lesson_subject: LessonSubject;
    lesson_title: string;
    challenge_id: number;
    created_by: number;
    opponent_id: number;
    opponent_firstname: string;
    opponent_score: number;
    my_score: number;
  };
};

export type InAppNotification = {
  id: number;
  recipient_id: number;
  read_at: string;
  shown_at: string;
  created_at: string;
  updated_at: string;
} & (
  | QuizReadyInAppNotification
  | LessonPieceProcessFailInAppNotification
  | ChallengeFinishedInAppNotification
);
