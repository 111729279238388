import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useUpdateCurrentUser, useCurrentUser } from 'src/queries/user';

import Button from 'src/components/common/designSystem/Button';
import Input from 'src/components/common/designSystem/Input';
import { useCreateUser, useGetUsers } from 'src/queries/users';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Footer from 'src/components/common/designSystem/Footer';
import { Child, UserType } from 'src/types/user.types';

const ParentFirstnameStep = ({
  onFinish,
}: {
  onFinish: (child: Child) => void;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const { data: currentUser } = useCurrentUser();

  const [firstnameSaved, setFirstnameSaved] = useState(false);
  const { mutateAsync, isLoading } = useUpdateCurrentUser();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser();

  const child = useRef<Child>();

  const saveFirstname = async ({ firstname }: { firstname: string }) => {
    try {
      child.current = users?.find(
        user => user.user_type === UserType.child,
      ) as Child;
      const onboarding_completed = child.current?.onboarding_completed;
      await mutateAsync({
        firstname,
        onboarding_completed,
      });
      setFirstnameSaved(true);
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t('onboarding.parent.firstname.fail'),
      });
    }
    try {
      if (child.current) {
        return;
      }
      const createdChild = await createUser({
        user_type: UserType.child,
      });
      child.current = createdChild as Child;
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Form
        name="parentFirstnameForm"
        onFinish={saveFirstname}
        className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3"
        disabled={isLoading || isLoadingUsers || isCreating}
        initialValues={{ firstname: currentUser.firstname }}
      >
        <OnboardingTitle title={t('onboarding.parent.firstname.title')} />
        <Form.Item
          name="firstname"
          className="w-full text-center"
          rules={[
            {
              required: true,
              message: t('global.validation.fieldRequired'),
            },
          ]}
        >
          <Input
            data-testid="inp-firstname"
            autoComplete="given-name"
            className="!border-none !bg-transparent !shadow-none text-center !text-2xl font-bold"
            placeholder={t('onboarding.parent.firstname.label')}
          />
        </Form.Item>
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="parentFirstnameForm"
          type="primary"
          htmlType="submit"
          disabled={isLoading || isLoadingUsers || isCreating}
          loading={isLoading || isLoadingUsers || isCreating}
          className="w-full"
        >
          {t('onboarding.next')}
        </Button>
      </Footer>

      <AutoFloatingPanel isOpen={firstnameSaved} closable={false}>
        <div className={'flex flex-col justify-center gap-2 p-4'}>
          <div className={'text-4xl text-center'}>👋</div>
          <div className={'text-lg font-bold text-center'}>
            {t('onboarding.parent.firstname.panel.title', {
              firstname: currentUser.firstname,
            })}
          </div>
          <div className={'text-base text-center whitespace-pre-line'}>
            <p>{t('onboarding.parent.firstname.panel.description')}</p>
          </div>
          <Button
            className={'w-full mt-8'}
            type={'primary'}
            disabled={!child.current}
            data-testid={'btn-continue'}
            loading={isCreating}
            onClick={() => child.current && onFinish(child.current)}
          >
            {t('global.continue')}
          </Button>
        </div>
      </AutoFloatingPanel>
    </>
  );
};

export default ParentFirstnameStep;
