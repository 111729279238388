import { useEffect, useState } from 'react';
import AlwaysScrollToBottom from 'src/components/common/animations/AlwaysScrollToBottom';

const TextTyping = ({
  text,
  onFinish,
}: {
  text: string;
  onFinish?: () => void;
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (import.meta.env.VITE_CI) {
        setIndex(text.length);
        if (onFinish) {
          onFinish();
        }
        return;
      }
      if (index < text.length) {
        setIndex(index + 1);
      } else {
        if (onFinish) {
          onFinish();
        }
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [index, onFinish, text]);

  return (
    <div className={'w-full h-full overflow-auto'}>
      {text.slice(0, index)}
      <AlwaysScrollToBottom />
    </div>
  );
};

export default TextTyping;
