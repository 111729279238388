import { useTranslation } from 'react-i18next';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import Logo from 'src/images/logoHorizontal.svg?react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentUser, useUpdateCurrentUser } from 'src/queries/user';
import { useNotification } from 'src/contexts/NotificationContext';
import Spinner from 'src/components/common/designSystem/Spinner';
import { updateLocalStorageUserID } from 'src/utils/usersUtils';
import { UserType } from 'src/types/user.types';

const Onboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();
  const { mutateAsync: updateUser, isLoading } = useUpdateCurrentUser();
  const { showError } = useNotification();

  const [searchParams] = useSearchParams();

  const onSelect = async (role: 'child' | 'parent') => {
    if (isLoading) {
      return;
    }

    if (role === 'parent') {
      if (user?.user_type === UserType.parent) {
        updateLocalStorageUserID(user.id!);
      }
      navigate(`/onboarding/parent/firstname?${searchParams.toString()}`);
      return;
    }

    try {
      const user = await updateUser({
        user_type: UserType.child,
      });
      updateLocalStorageUserID(user.id!);
      navigate(`/onboarding/child/firstname?${searchParams.toString()}`);
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  return (
    <BlankPrimaryLayout>
      <div
        className={'flex flex-row justify-between items-center text-white p-4'}
      >
        <Logo className={'h-4'} />
      </div>
      <div
        className={
          'flex flex-col justify-between flex-1 items-center px-6 py-10 text-white'
        }
      >
        <div className={'font-bold text-xl text-center text-balance'}>
          {t('onboarding.who.title')}
        </div>
        <div className={'flex flex-row gap-8'}>
          <RoleItem
            emoji={'🧑'}
            title={t('onboarding.who.parent')}
            onClick={() => {
              onSelect('parent');
            }}
            data-testid={'role-parent'}
          />
          <RoleItem
            emoji={'🧒'}
            title={t('onboarding.who.child')}
            onClick={() => {
              onSelect('child');
            }}
            data-testid={'role-child'}
          />
        </div>
        {isLoading ? (
          <div>
            <Spinner type={'white'} size="xl" />
          </div>
        ) : (
          <div />
        )}
      </div>
    </BlankPrimaryLayout>
  );
};

const RoleItem = ({
  title,
  emoji,
  onClick,
  'data-testid': dataTestId,
}: {
  title: string;
  emoji: string;
  onClick: () => void;
  'data-testid'?: string;
}) => {
  return (
    <div
      className={'flex flex-col justify-center items-center gap-2'}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <div
        className={
          'bg-white bg-opacity-25 rounded-full p-6 text-4xl leading-none'
        }
      >
        {emoji}
      </div>
      <div className={'text-base font-bold'}>{title}</div>
    </div>
  );
};

export default Onboarding;
