import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import Button from 'src/components/common/designSystem/Button';
import Input from 'src/components/common/designSystem/Input';
import { useUpdateUser } from 'src/queries/users';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import { Navigate } from 'react-router-dom';
import Footer from 'src/components/common/designSystem/Footer';

import { Child } from 'src/types/user.types';

const ChildFirstnameStep = ({
  child,
  onFinish,
  isMe,
}: {
  child: Child;
  onFinish: () => void;
  isMe?: boolean;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const tNamespace = isMe
    ? 'onboarding.child.firstname'
    : 'onboarding.parent.child.firstname';

  const { mutateAsync: updateChild, isLoading: isUpdating } = useUpdateUser();

  const saveFirstname = async ({ firstname }: { firstname: string }) => {
    try {
      await updateChild({
        id: child.id,
        firstname,
      });
      onFinish();
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t(`${tNamespace}.fail`),
      });
    }
  };

  if (!child) {
    return <Navigate to={'/onboarding/parent/firstname'} replace />;
  }

  return (
    <>
      <Form
        name="childFirstnameForm"
        onFinish={saveFirstname}
        className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3"
        disabled={isUpdating}
        initialValues={{ firstname: child.firstname }}
      >
        <OnboardingTitle title={t(`${tNamespace}.title`)} />
        <Form.Item
          name="firstname"
          className="w-full text-center"
          rules={[
            {
              required: true,
              message: t('global.validation.fieldRequired'),
            },
          ]}
        >
          <Input
            data-testid="inp-firstname"
            className="!border-none !bg-transparent !shadow-none text-center !text-2xl font-bold"
            placeholder={t(`${tNamespace}.label`)}
          />
        </Form.Item>
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="childFirstnameForm"
          type="primary"
          htmlType="submit"
          disabled={isUpdating}
          loading={isUpdating}
          className="w-full"
        >
          {t('onboarding.next')}
        </Button>
      </Footer>
    </>
  );
};

export default ChildFirstnameStep;
