import { useTranslation } from 'react-i18next';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { subjects } from 'src/utils/constants';
import { useCurrentUser } from 'src/queries/user';
import TrophyIcon from 'src/images/trophy.svg?react';

const ChallengeFinishedNotificationCard = ({
  notification,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.CHALLENGE_FINISHED;
  };
}) => {
  const { t } = useTranslation();
  const { data: me } = useCurrentUser();
  const params = notification.params;
  const subject = subjects[params.lesson_subject];
  const win = params.my_score >= params.opponent_score;
  const winnerFirstName = win
    ? (me?.firstname ?? '')
    : params.opponent_firstname;
  const loserFirstName = win
    ? params.opponent_firstname
    : (me?.firstname ?? '');

  return (
    <div
      data-testid={`notification-challenge-finished-${params.lesson_id}`}
      className={'flex flex-row bg-white rounded-xl p-4 gap-4'}
    >
      {subject ? (
        <div>
          <div className={'w-12 h-12 relative'}>
            <div
              className={`w-8 h-8 text-white font-bold text-lg border-2 border-white absolute flex items-center justify-center rounded-full right-0 top-0 ${win ? 'bg-primary-darkest' : 'bg-structural-darkest'}`}
            >
              {loserFirstName[0]}
            </div>
            <div
              className={`w-8 h-8 text-white font-bold text-lg border-2 rounded-full flex items-center justify-center border-white absolute left-0 top-0 ${win ? 'bg-structural-darkest' : 'bg-primary-darkest'}`}
            >
              {winnerFirstName[0]}
            </div>
            <div
              className={
                'absolute left-1 bottom-0 flex items-center justify-center w-6 h-6 rounded-full bg-structural-darkest border-2 border-white'
              }
            >
              <TrophyIcon className={'w-3 h-3'} />
            </div>
          </div>
        </div>
      ) : null}
      <div className={'flex flex-col w-full'}>
        <div
          className={'text-base font-bold text-balance text-structural-darkest'}
        >
          {t(
            params.my_score === params.opponent_score
              ? 'notifications.challenge_finished.equal'
              : win
                ? 'notifications.challenge_finished.win'
                : 'notifications.challenge_finished.lose',
            {
              opponentFirstName: params.opponent_firstname,
            },
          )}
        </div>
        <div className={'flex flex-row justify-between items-center'}>
          <div
            className={
              'font-semibold line-clamp-1 text-ellipsis overflow-hidden text-balance text-structural-neutral'
            }
          >
            {t(subject.labelKey)} • {notification.params.lesson_title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeFinishedNotificationCard;
