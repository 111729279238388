import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import Header from 'src/components/Header/Header';

export default function TermsAndConsitionsPage() {
  return (
    <LayoutWithHeader header={<Header backArrow />}>
      <div className="p-4">
        <h1>CONDITIONS GÉNÉRALES D&apos;UTILISATION</h1>
        <br />
        <p>Date de dernière mise à jour : 17 Avril 2024</p>
        <p>
          <br />
        </p>
        <h2 className="mt-4">Article 1 - MENTION LÉGALES</h2>
        <p>
          <a
            href="http://www.baobab-labs.com/"
            className="s3"
            target="_blank"
            rel="noreferrer"
          >
            Le présent site, accessible à l&apos;URL https://
          </a>
          www.baobab-labs.com (le « Site »), est édité par :
        </p>
        <p>
          Baobab Labs, société au capital de 1500 euros, inscrite au R.C.S. de
          Mâcon sous le numéro 985 354 158, dont le siège social est situé au 1
          impasse des amandiers 71700 Tournus, représentée par Pierre-Alexis
          Voisin dument habilité,
        </p>
        <p>
          (Ci-après désigné l&apos;« <b>Exploitant</b>»).
        </p>
        <p>
          <br />
        </p>
        <p>
          Le numéro individuel TVA de l&apos;Exploitant est : FR96985354158.
        </p>
        <p>
          <br />
        </p>
        <p>
          Le Site est hébergé par la société Carrd Inc., situé 231 Public Square
          Suite 300 PMB 12, Franklin TN 37064<i>, </i>téléphone : +1(615)
          645-2470.
        </p>
        <p>
          <br />
        </p>
        <p>Le Directeur de la publication du Site est Adrien Pointillart.</p>
        <p>
          L&apos;Exploitant peut être joint au numéro de téléphone suivant +33 6
          95 41 50 15 et à l&apos;adresse mail suivante:
        </p>
        <p>
          <a
            href="mailto:adrien@baobab-labs.com"
            target="_blank"
            rel="noreferrer"
          >
            adrien@baobab-labs.com
          </a>
          .
        </p>
        <h2 className="mt-4">Article 2 - DESCRIPTION DU SERVICE</h2>
        <p>
          Le Site est mis à la disposition de toute personne accédant au site
          (l&apos; « <b>Utilisateur </b>») pour
        </p>
        <p>information sur l&apos;offre à venir de Baobab Labs.</p>
        <p>
          Le Site n&apos;est pas un site marchand et ne propose aucune
          transaction commerciale à distance.
        </p>
        <p>
          L&apos;Utilisateur demeure responsable des modalités et des
          conséquences de son accès au Site notamment par l&apos;Internet. Cet
          accès peut impliquer le paiement de frais à des prestataires
          techniques tels que notamment des fournisseurs d&apos;accès à
          l&apos;Internet, lesquels demeurent à sa charge. En outre,
          l&apos;Utilisateur devra fournir et être entièrement responsable des
          équipements nécessaires afin de se connecter au Site.
        </p>
        <p>
          <br />
        </p>
        <p>
          L&apos;Utilisateur reconnait avoir vérifié que la configuration
          informatique qu&apos;il utilise est sécurisée
        </p>
        <p>et en état de fonctionnement.</p>
        <p>
          Les informations et services proposés par le Site sont gratuits, et
          accessibles 24h/24h et 7jours/7 jours, sauf en cas de force majeure,
          de pannes informatiques, d&apos;opérations de maintenance ou de
          problèmes liés aux réseaux de télécommunications.
        </p>
        <h2 className="mt-4">
          Article 3 - PROPRIÉTÉ INTELLECTUELLE ET LICENCE D&apos;UTILISATION DU
          SITE
        </h2>
        <p>
          L&apos;Exploitant est seul titulaire de tous les éléments présents sur
          le Site, notamment et sans limitation, tous textes, fichiers, images
          animées ou non, photographies, vidéos, logos, dessins, modèles,
          logiciels, marques, identité visuelle, base de données, structure du
          Site et tous autres éléments de propriété intellectuelle et autres
          données ou informations (ci-après, les « Éléments») qui sont protégés
          par les lois et règlements français et internationaux relatifs
          notamment à la propriété intellectuelle.
        </p>
        <p>
          <br />
        </p>
        <p>
          En conséquence, aucun des Éléments du Site ne pourra en tout ou partie
          être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis,
          publié, communiqué, distribué, diffusé, représenté, stocké, utilisé,
          loué ou exploité de toute autre manière, à titre gratuit ou onéreux,
          par un Utilisateur ou par un tiers, quel que soient les moyens et/ou
          les supports utilisés, qu&apos;ils soient connus ou inconnus à ce
          jour, sans l&apos;autorisation préalable exprès et écrite de
          l&apos;Exploitant au cas par cas, et l&apos;Utilisateur est seul
          responsable de toute utilisation et/ou exploitation non autorisée.
        </p>
        <p>
          <br />
        </p>
        <h2 className="mt-4">ARTICLE 4 - RESPONSABILITÉ ET GARANTIE</h2>
        <p>
          <br />
        </p>
        <p>
          L&apos;Utilisateur reconnaît que les caractéristiques et les
          contraintes d&apos;Internet ne permettent pas de garantir la sécurité,
          la disponibilité et l&apos;intégrité des transmissions de données sur
          Internet. Ainsi, l&apos;Exploitant ne garantit pas que le Site et ses
          services fonctionneront sans interruption ni erreur de fonctionnement.
          En particulier, leur exploitation pourra être momentanément
          interrompue pour cause de maintenance, de mises à jour ou
          d&apos;améliorations techniques, ou pour en faire évoluer le contenu
          et/ou leur présentation.
        </p>
        <p>
          <br />
        </p>
        <p>
          L&apos;Exploitant ne peut être tenu pour responsable de
          l&apos;utilisation qui serait faite du Site et de ses Éléments par les
          Utilisateurs en violation des présentes Conditions Générales
          d&apos;Utilisation et des dommages directs ou indirects que cette
          utilisation pourrait causer à un Utilisateur ou à un tiers. En
          particulier, l&apos;Exploitant ne peut être tenu pour responsable des
          fausses déclarations faites par un Utilisateur et de son comportement
          vis-à-vis des tiers. Dans le cas où la responsabilité de
          l&apos;Exploitant serait recherchée à raison d&apos;un tel
          comportement d&apos;un de ses Utilisateurs, ce dernier s&apos;engage à
          garantir l&apos;Exploitant contre toute condamnation prononcée à son
          encontre ainsi qu&apos;à rembourser l&apos;Exploitant de
          l&apos;ensemble des frais, notamment les honoraires d&apos;avocats,
          engagés pour sa défense.
        </p>
        <h2 className="mt-4">ARTICLE 5 - DONNÉES PERSONNELLES</h2>
        <p>
          Pour davantage d&apos;informations concernant l&apos;utilisation de
          données à caractère personnel par l&apos;Exploitant, veuillez lire
          attentivement la Charte sur le respect de la vie privée (la «{' '}
          <b>Charte </b>»). Vous pouvez à tout moment consulter cette Charte sur
          le Site.
        </p>
        <p>
          <br />
        </p>
        <h2 className="mt-4">Article 6 - LIENS HYPERTEXTES</h2>
        <p>
          Les liens hypertextes disponibles sur le Site peuvent renvoyer vers
          des sites tiers non édités par l&apos;Exploitant. Ils sont fournis
          uniquement pour la convenance de l&apos;Utilisateur, afin de faciliter
          l&apos;utilisation des ressources disponibles sur l&apos;Internet. Si
          l&apos;Utilisateur utilise ces liens, il quittera le Site et acceptera
          alors d&apos;utiliser les sites tiers à ses risques et périls ou le
          cas échéant conformément aux conditions qui les régissent.
        </p>
        <p>
          <br />
        </p>
        <p>
          L&apos;Utilisateur reconnait que l&apos;Exploitant ne contrôle ni ne
          contribue en aucune manière à l&apos;élaboration des conditions
          d&apos;utilisation et/ou du contenu s&apos;appliquant à ou figurant
          sur ces sites tiers.
        </p>
        <p>
          En conséquence, l&apos;Exploitant ne saurait être tenu responsable de
          quelque façon que ce soit
        </p>
        <p>du fait de ces liens hypertextes.</p>
        <p>
          En outre, l&apos;Utilisateur reconnait que l&apos;Exploitant ne
          saurait cautionner, garantir ou reprendre à son compte tout ou partie
          des conditions d&apos;utilisation et/ou du contenu de ces sites tiers.
        </p>
        <p>
          Le Site peut également contenir des liens hypertextes promotionnels
          et/ou bandeaux
        </p>
        <p>
          publicitaires renvoyant vers des sites tiers non édités par
          l&apos;Exploitant.
        </p>
        <p>
          <br />
        </p>
        <p>
          L&apos;Exploitant invite l&apos;Utilisateur à lui signaler tout lien
          hypertexte présent sur le Site qui permettrait d&apos;accéder à un
          site tiers proposant du contenu contraire aux lois et/ou aux bonnes
          mœurs.
        </p>
        <p>
          <br />
        </p>
        <p>
          L&apos;Utilisateur ne pourra pas utiliser et/ou insérer de lien
          hypertexte pointant vers le site sans l&apos;accord écrit et préalable
          de l&apos;Exploitant au cas par cas.
        </p>
        <p>
          <br />
        </p>
        <h2 className="mt-4">ARTICLE 7 - DISPOSITIONS GÉNÉRALES</h2>
        <h3>INTEGRALITÉ DE L&apos;ACCORD DES PARTIES</h3>
        <p>
          <br />
        </p>
        <p>
          Les présentes Conditions Générales d&apos;Utilisation constituent un
          contrat régissant les relations entre l&apos;Utilisateur et
          l&apos;Exploitant. Elles constituent l&apos;intégralité des droits et
          obligations de l&apos;Exploitant et de l&apos;Utilisateur relatifs à
          leur objet. Si une ou plusieurs stipulations des présentes Conditions
          Générales d&apos;Utilisation étaient déclarées nulles en application
          d&apos;une loi, d&apos;un règlement ou à la suite d&apos;une décision
          définitive d&apos;une juridiction compétente, les autres stipulations
          garderont toute leur force et leur portée. En outre, le fait pour une
          des parties aux présentes Conditions Générales d&apos;Utilisation de
          ne pas se prévaloir d&apos;un manquement de l&apos;autre partie à
          l&apos;une quelconque des dispositions des présentes Conditions
          Générales
        </p>
        <p>
          d&apos;Utilisation ne saurait s&apos;interpréter comme une
          renonciation de sa part à se prévaloir dans
        </p>
        <p>l&apos;avenir d&apos;un tel manquement.</p>
        <h3>MODIFICATIONS DES CONDITIONS D&apos;UTILISATION</h3>
        <p>
          L&apos;Exploitant se réserve le droit de modifier à tout moment et
          sans préavis le contenu du Site ou des services qui y sont
          disponibles, et/ou de cesser de manière temporaire ou définitive
          d&apos;exploiter tout ou partie du Site.
        </p>
        <p>
          En outre, l&apos;Exploitant se réserve le droit de modifier à tout
          moment et sans préavis la localisation du Site sur l&apos;Internet,
          ainsi que les présentes Conditions Générales d&apos;Utilisation.
          L&apos;Utilisateur est donc tenu par conséquent de se reporter aux
          présentes Conditions Générales d&apos;Utilisation avant toute
          utilisation du Site. En cas de modifications matérielles,
          l&apos;Utilisateur sera informé au moyen d&apos;un email et d&apos;un
          avertissement sur le Site avant la mise en application de la
          modification.
        </p>
        <p>
          L&apos;Utilisateur reconnait que l&apos;Exploitant ne saurait être
          tenu responsable de quelque manière
        </p>
        <p>
          que ce soit envers lui ou tout tiers du fait de ces modifications,
          suspensions ou cessations.
        </p>
        <h3>RÉCLAMATION - MÉDIATION</h3>
        <p>
          En cas de litige, vous devez vous adresser en priorité au service
          client de l&apos;entreprise aux coordonnées suivantes:
          <a
            href="mailto:service-client@baobab-labs.com"
            target="_blank"
            rel="noreferrer"
            className="ml-1"
          >
            service-client@baobab-labs.com
          </a>
          .
        </p>
        <p>
          En cas d&apos;échec de la demande de réclamation auprès du service
          client ou en l&apos;absence de réponse de ce service dans un délai de
          30 jours, le Client peut soumettre le différend relatif au bon de
          commande ou aux présentes CGV l&apos;opposant à l&apos;Exploitant au
          médiateur suivant : Médiateur-Consommation-smp, 24, rue Albert de Mun
          33000 Bordeaux.
        </p>
        <p>
          <br />
        </p>
        <p>
          Le médiateur tentera, en toute indépendance et impartialité, de
          rapprocher les parties en vue d&apos;aboutir à une solution amiable.
          Les parties restent libres d&apos;accepter ou de refuser le recours à
          la médiation ainsi que, en cas de recours à la médiation,
          d&apos;accepter ou de refuser la solution proposée par le médiateur.
        </p>
        <p>
          <br />
        </p>
        <h2>DROIT APPLICABLE</h2>
        <p>
          Ces Conditions Générales d&apos;Utilisation sont régies, interprétées
          et appliquées conformément
        </p>
        <p>au droit français.</p>
        <br />
        <p>
          L&apos;Utilisateur reconnait avoir lu attentivement les présentes
          Conditions Générales d&apos;Utilisation.
        </p>
        <p>
          En s&apos;inscrivant sur le Site, l&apos;Utilisateur confirme avoir
          pris connaissance des Conditions Générales d&apos;Utilisation et les
          accepter, le rendant contractuellement lié par les termes des
          présentes Conditions Générales d&apos;Utilisation.
        </p>
        <p>
          Les Conditions Générales d&apos;Utilisation applicables à
          l&apos;Utilisateur sont celles mises en ligne sur le Site. En cas de
          modification, l&apos;Exploitant publiera ces changements sur le Site
          pour que l&apos;Utilisateur sache quelles informations sont
          collectées, comment elles sont utilisées, dans quelles circonstances,
          et le cas échéant, comment elles sont divulguées. En cas de
          modifications matérielles, l&apos;Utilisateur sera informé au moyen
          d&apos;un email et d&apos;un avertissement sur le Site avant la mise
          en application de la modification.
        </p>
      </div>
    </LayoutWithHeader>
  );
}
