import { useTranslation } from 'react-i18next';
import UserManagement from 'src/components/UserManagement/UserManagement';
import BlankLayout from 'src/layouts/BlankLayout';
import AddChildFloatingButton from 'src/components/UserManagement/AddChildFloatingButton';
import ProfileButton from 'src/components/Profile/ProfileButton';

function ParentHomePage() {
  const { t } = useTranslation();

  return (
    <BlankLayout>
      <div className={'flex flex-col gap-2 pt-2 flex-1'}>
        <div className={'flex flex-row justify-between items-center px-4'}>
          <div className={'text-2xl font-bold'}>{t('settings.title')}</div>
          <ProfileButton />
        </div>
        <div className={'flex flex-col flex-grow'}>
          <UserManagement />
        </div>
      </div>
      <AddChildFloatingButton />
    </BlankLayout>
  );
}

export default ParentHomePage;
