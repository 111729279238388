import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getSubjectInfo } from 'src/utils/subject';
import { Lesson } from 'src/types/lesson.types';

export default function LessonCardSmall({ lesson }: { lesson: Lesson }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/lessons/${lesson.id}`);
  };
  const subject = getSubjectInfo(lesson.subject_matter);

  return (
    <div
      className={
        'flex flex-row items-center px-5 py-3.5 w-full gap-4 cursor-pointer bg-white rounded-3xl'
      }
      onClick={handleClick}
      data-testid={`card-lesson-${lesson.id}`}
    >
      <div>
        <div
          className={
            'w-14 h-14 relative overflow-hidden bg-primary-neutral rounded-2xl flex items-center justify-center p-3'
          }
        >
          <subject.IconComponent className={'w-full h-full'} />
        </div>
      </div>
      <div className={'flex flex-col flex-grow overflow-hidden'}>
        <div
          className={
            'text-base overflow-ellipsis line-clamp-2 w-full overflow-hidden font-bold text-structural-darkest'
          }
        >
          {lesson.title}
        </div>
        <div className={'text-structural-dark'}>{t(subject.labelKey)}</div>
      </div>
      <div>
        <IconMdiChevronRight fontSize={24} />
      </div>
    </div>
  );
}
