import { useTranslation } from 'react-i18next';
import {
  DatePicker as AntdDatePicker,
  type DatePickerProps,
} from 'antd-mobile';
import { type PropsWithTestId } from 'src/types';

const DatePicker = (props: DatePickerProps & PropsWithTestId) => {
  const { t } = useTranslation();
  return (
    <AntdDatePicker
      {...props}
      cancelText={t('global.cancel')}
      confirmText={t('global.ok')}
      mouseWheel
    />
  );
};

export default DatePicker;
