import { Lesson, LessonSubject } from 'src/types/lesson.types';

export const lessonIsProcessing = (lesson?: Lesson) => {
  if (!lesson) return false;

  return lesson.lesson_pieces?.some(
    piece => !piece.post_process_completed && piece.transcript_job_id,
  );
};

export const getAddLessonPieceURL = (
  lessonId: number,
  subject: LessonSubject,
): string => {
  switch (subject) {
    case LessonSubject.geography:
    case LessonSubject.history:
    case LessonSubject.sciences:
    case LessonSubject.physics:
    case LessonSubject.mathematics:
      return `/lessons/${lessonId}/pieces/audio`;
    case LessonSubject.english:
    case LessonSubject.spanish:
    case LessonSubject.french:
      return `/lessons/${lessonId}/pieces/photo`;
  }
};
