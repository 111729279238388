import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useNotification } from 'src/contexts/NotificationContext';
import { Form } from 'antd';
import BlankLayout from 'src/layouts/BlankLayout';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';
import EmailInput from 'src/components/Login/EmailInput';

function LoginEmail() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showError } = useNotification();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const onSubmit = async ({ email }: { email: string }) => {
    setIsLoading(true);
    try {
      const { signInMethods } =
        await FirebaseAuthentication.fetchSignInMethodsForEmail({
          email,
        });
      const exists = signInMethods.includes('password');
      if (exists) {
        navigate(`/login/password?email=${email}&${searchParams.toString()}`);
      } else {
        navigate(
          `/login/create-account?email=${email}&${searchParams.toString()}`,
        );
      }
    } catch (error) {
      console.error(error);
      showError({
        message: t('login.errors.defaultError'),
        error,
      });
    }
    setIsLoading(false);
  };

  return (
    <BlankLayout>
      <BackArrowHeader />
      <Form
        name="emailForm"
        onFinish={onSubmit}
        className="flex-1 bg-transparent flex flex-col justify-between items-center px-4 gap-4"
        disabled={isLoading}
      >
        <div className={'font-extrabold text-base text-balance text-center'}>
          {t('login.title')}
        </div>
        <EmailInput />
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="emailForm"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {t('global.continue')}
        </Button>
      </Footer>
    </BlankLayout>
  );
}

export default LoginEmail;
