import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSwitchUser } from 'src/queries/sessions';
import { useNotification } from 'src/contexts/NotificationContext';

const useSwitchToChildOnboarding = ({ childId }: { childId: number }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: switchUser, isLoading: switching } = useSwitchUser();
  const [searchParams] = useSearchParams();
  const { showError } = useNotification();

  const endOnboarding = useCallback(async () => {
    try {
      await switchUser(childId);
      navigate(
        `/onboarding/child/objective?showParentPanel=true&${searchParams.toString()}`,
      );
    } catch (error) {
      console.error(error);
      showError({
        message: t('error.http.default'),
        error,
      });
    }
  }, [switchUser, childId, navigate, searchParams, showError, t]);

  return { switchToChildOnboarding: endOnboarding, isSwitching: switching };
};

export default useSwitchToChildOnboarding;
