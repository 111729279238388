import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from 'src/images/calendar.svg?react';
import dayjs from 'dayjs';
import { useNotification } from 'src/contexts/NotificationContext';
import { useUpdateLesson } from 'src/queries/lessons';
import DatePicker from 'src/components/common/designSystem/DatePicker';
import { Lesson } from 'src/types/lesson.types';

const LessonEvaluation = ({ lesson }: { lesson: Lesson }) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { mutateAsync: updateLesson } = useUpdateLesson();

  const sendEvaluationDate = async (date: Date) => {
    try {
      await updateLesson({
        id: lesson.id,
        evaluation_date: dayjs(date).format('YYYY-MM-DD'),
      });
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t('lessons.evaluation.failToUpdate'),
      });
    }
  };

  return (
    <>
      <div
        data-testid={'inp-evaluation-date'}
        onClick={() => {
          setShowDatePicker(true);
        }}
        className={'py-2 px-2.5 bg-white bg-opacity-20 rounded-xl w-fit'}
      >
        <div className={'flex gap-2 flex-row items-center justify-start w-fit'}>
          <Calendar className={'w-4 h-4'} />
          {lesson.evaluation_date ? (
            <div className={'text-base font-semibold'}>
              {dayjs(lesson.evaluation_date).format('D MMM')}
            </div>
          ) : (
            <div className={'text-base font-extrabold'}>{t('global.add')}</div>
          )}
        </div>
      </div>
      <DatePicker
        title={t('lessons.evaluation.add')}
        visible={showDatePicker}
        value={
          lesson.evaluation_date ? new Date(lesson.evaluation_date) : undefined
        }
        onClose={() => {
          setShowDatePicker(false);
        }}
        onConfirm={val => {
          setShowDatePicker(false);
          sendEvaluationDate(val);
        }}
      />
    </>
  );
};

export default LessonEvaluation;
