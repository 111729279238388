import { useGetUsers } from 'src/queries/users';
import Spinner from 'src/components/common/designSystem/Spinner';
import { Navigate } from 'react-router-dom';
import ChildFirstnameStep from 'src/components/Onboarding/ChildFirstnameStep';
import { Child, UserType } from 'src/types/user.types';

const ParentChildFirstnameStep = ({
  childId,
  onFinish,
}: {
  childId: number;
  onFinish: () => void;
}) => {
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();

  const child = users?.find(
    user => user.id === childId && user.user_type === UserType.child,
  ) as Child | undefined;

  if (isLoadingUsers) {
    return <Spinner size="xl" className="w-full h-full" />;
  }

  if (!child) {
    return <Navigate to={'/onboarding/parent/firstname'} replace />;
  }

  return <ChildFirstnameStep child={child} onFinish={onFinish} />;
};

export default ParentChildFirstnameStep;
