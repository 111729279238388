import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';

import { allSubjects, nonSpanishSubjects } from 'src/utils/constants';
import SubjectListItem from 'src/components/Subject/SubjectListItem';
import { useFlags } from 'src/contexts/FlagsContext';

import { LessonSubject } from 'src/types/lesson.types';

export default function AddLessonSelectSubject({
  onSubjectSelected,
}: {
  onSubjectSelected: (subject: LessonSubject) => Promise<void>;
} & ButtonProps) {
  const { t } = useTranslation();
  const { display_spanish_subject } = useFlags();
  const subjects = display_spanish_subject ? allSubjects : nonSpanishSubjects;

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-row justify-center items-center w-full'}>
        <div className={'text-base font-semibold'}>
          {t('home.addLesson.chooseASubject')}
        </div>
      </div>
      <div className={'flex flex-col gap-4 pt-2'}>
        {subjects.map(subject => {
          return (
            <SubjectListItem
              subject={subject.key}
              key={subject.key}
              onClick={async () => {
                await onSubjectSelected(subject.key);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
