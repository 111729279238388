import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthMode } from 'src/contexts/AuthContext';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { AuthError, authErrorCodeToMessage } from 'src/modules/firebase';
import { useNotification } from 'src/contexts/NotificationContext';
import { useAuth } from 'src/hooks/useAuth';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import { Form } from 'antd';
import BlankLayout from 'src/layouts/BlankLayout';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';
import SetPasswordInputs from 'src/components/Login/SetPasswordInputs';

function LoginCreateAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showError } = useNotification();
  const { setMode } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  useEffect(() => {
    if (!email) {
      navigate('/', { replace: true });
    }
  }, [email, navigate]);

  const onSignup = async ({ password }: { password: string }) => {
    if (!email) {
      return;
    }
    setIsLoading(true);
    try {
      setMode(AuthMode.SIGNUP);
      await FirebaseAuthentication.createUserWithEmailAndPassword({
        email,
        password,
      });

      navigate(`/onboarding?${searchParams.toString()}`, { replace: true });
    } catch (error) {
      const message = authErrorCodeToMessage(error as AuthError);
      if (message) {
        console.error(error);
        showError({
          message: message,
          error,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <BlankLayout>
      <BackArrowHeader />
      <Form
        name="signupForm"
        onFinish={onSignup}
        className="flex-1 bg-transparent flex flex-col justify-between items-center px-4 gap-4"
        disabled={isLoading}
      >
        <div className={'font-extrabold text-base text-balance text-center'}>
          {t('login.title')}
        </div>
        <div className="w-full">
          <SetPasswordInputs />
        </div>
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="signupForm"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {t('global.continue')}
        </Button>
      </Footer>
    </BlankLayout>
  );
}

export default LoginCreateAccount;
