import { useTranslation } from 'react-i18next';
import PaywallCheckIcon from 'src/components/Paywall/PaywallCheckIcon';

const MonthlyOffer = () => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'w-full items-center bg-gradient-to-r from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-1'
      }
    >
      <div
        className={
          'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-6'
        }
      >
        <div className={'flex flex-row gap-4 items-center justify-center'}>
          <PaywallCheckIcon />
          <div className={'font-extrabold text-lg text-structural-darker'}>
            {t('paywall.panel.type')}
          </div>
        </div>
        <div className={'flex flex-col text-right'}>
          <div
            className={
              'font-extrabold text-lg leading-none text-structural-darker'
            }
          >
            {t('paywall.panel.price')}
          </div>
          <div className={'text-base leading-none text-structural-darker'}>
            {t('paywall.panel.time')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyOffer;
