export default {
  notFound: 'Invitation invalide',
  invite: "Créer un lien d'invitation",
  modalQrTitle: 'Scannez ce QR code avec le téléphone de votre enfant',
  modalShareTitle: 'Partagez ce lien d’inscription à votre enfant',
  shareLink: 'Partager le lien',
  linkExpires: 'Note: le lien est valide 24h',
  child: {
    button: 'Inviter {{firstName}}',
  },
  parent: {
    button: 'Demander à un adulte',
    paywall: {
      newParent:
        'J’utilise Baobab pour réviser mes leçons. Ma période d’essai est terminée, j’ai besoin que tu crées un compte parent pour continuer',
      existingParent:
        'J’utilise Baobab pour réviser mes leçons. Ma période d’essai est terminée, peux-tu souscrire à l’abonnement pour que je continue ?',
    },
    error: 'Une erreur est survenue, veuillez réessayer',
  },
};
