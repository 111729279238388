import { useEffect } from 'react';
import { useCurrentUser } from 'src/queries/user';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Login from 'src/pages/login';
import ResetPassword from 'src/pages/reset_password';
import Invitation from 'src/pages/invitations';
import TermsAndConsitionsPage from 'src/pages/terms-and-conditions';
import HomeParentPage from 'src/pages/parent/home';
import Landing from 'src/pages/index';
import Home, { HomeTab } from 'src/pages/child/home';
import Lesson from 'src/pages/lessons/[lessonId]';
import LessonQuiz from 'src/pages/lessons/[lessonId]/quizzes/[quizId]/';
import LessonQuizResult from 'src/pages/lessons/[lessonId]/quizzes/[quizId]/runs/[runId]/result';
import * as Sentry from '@sentry/react';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import FirebaseAuthActions from 'src/pages/auth/action';
import LessonAddPhoto from 'src/pages/lessons/[lessonId]/pieces/photo';
import Spinner from 'src/components/common/designSystem/Spinner';
import AddPieces from 'src/pages/lessons/[lessonId]/pieces/add';
import LessonAddAudio from 'src/pages/lessons/[lessonId]/pieces/audio';
import FamilyUserSelect from 'src/components/FamilyUserSelect';
import LessonRevisionPage from 'src/pages/lessons/[lessonId]/revision';
import LessonProgressPage from 'src/pages/lessons/[lessonId]/progress';
import LessonImprovementsPage from 'src/pages/lessons/[lessonId]/improvements';
import LessonRenamePage from 'src/pages/lessons/[lessonId]/rename';
import { Modal } from 'antd-mobile';
import OnboardingParentFirstname from 'src/pages/onboarding/parent/firstname';
import OnboardingChildObjective from 'src/pages/onboarding/child/objective';
import { loginOneSignal } from 'src/utils/onesignal';
import ConfidentialitePage from 'src/pages/confidentialite';
import { identifyUser } from 'src/modules/analytics';
import LessonPieceProcessing from 'src/pages/lessons/[lessonId]/pieces/[pieceId]/processing';
import LoginPassword from 'src/pages/login/password';
import LoginEmail from 'src/pages/login/email';
import LoginCreateAccount from 'src/pages/login/create-account';
import Onboarding from 'src/pages/onboarding';
import OnboardingParentChildFirstname from 'src/pages/onboarding/parent/[childId]/firstname';
import OnboardingParentChildYear from 'src/pages/onboarding/parent/[childId]/year';
import OnboardingParentChildGender from 'src/pages/onboarding/parent/[childId]/gender';
import OnboardingParentChildSchoolLevel from 'src/pages/onboarding/parent/[childId]/school_level';
import ParentChildSchoolLevel from 'src/pages/child/[childId]/school_level';
import ParentChildGender from 'src/pages/child/[childId]/gender';
import ParentChildYear from 'src/pages/child/[childId]/year';
import ParentChildFirstname from 'src/pages/child/[childId]/firstname';
import OnboardingChildFirstname from 'src/pages/onboarding/child/firstname';
import OnboardingChildYear from 'src/pages/onboarding/child/year';
import OnboardingChildGender from 'src/pages/onboarding/child/gender';
import OnboardingChildSchoolLevel from 'src/pages/onboarding/child/school_level';
import { logInAppPurchase, logOutInAppPurchase } from 'src/modules/purchase';
import {
  getLocalStorageUserID,
  updateLocalStorageUserID,
} from 'src/utils/usersUtils';
import ProfileChildPage from 'src/pages/child/profile';
import ProfileParentPage from 'src/pages/parent/profile';
import ChallengePage from 'src/pages/challenges/[challengeId]';
import LogoutChallengePage from 'src/pages/challenges/[challengeId]/logout';
import { useAuth } from 'src/hooks/useAuth';
import ChallengeLessonQuiz from 'src/pages/challenges/[challengeId]/lessons/[lessonId]/quizzes/[quizId]/runs/[runId]';
import ChallengeLessonQuizResult from 'src/pages/challenges/[challengeId]/lessons/[lessonId]/quizzes/[quizId]/runs/[runId]/result';
import AnonymousChallengeLessonQuizResult from 'src/pages/challenges/[challengeId]/lessons/[lessonId]/quizzes/[quizId]/runs/[runId]/result.anonymous';
import LessonsPage from 'src/pages/lessons';
import RedirectOnLogin from 'src/components/RedirectOnLogin';
import NotificationsPage from 'src/pages/notifications';
import OnboardingParentChildDevice from 'src/pages/onboarding/parent/[childId]/school/device';
import OnboardingParentChildPaywallDescription from 'src/pages/onboarding/parent/[childId]/school/device/paywall/description';
import OnboardingParentChildPaywallPay from 'src/pages/onboarding/parent/[childId]/school/device/paywall/pay';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import OnboardingChildPaywallDescription from 'src/pages/onboarding/child/objective/paywall/description';
import ParentChildDevicePage from 'src/pages/child/[childId]/school/device';
import OnboardingParentChildOtherDevice from 'src/pages/onboarding/parent/[childId]/school/device/other';
import ParentChildOtherDevicePage from 'src/pages/child/[childId]/school/device/other';
import { User, UserType } from 'src/types/user.types';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const PublicRoutes = (
  <>
    <Route path="/reset_password">
      <Route index element={<ResetPassword />} />
    </Route>
    <Route path="/auth/action" element={<FirebaseAuthActions />} />
    <Route path="/invitations" element={<Invitation />} />
    <Route path="/terms-and-conditions" element={<TermsAndConsitionsPage />} />
    <Route path="/confidentialite" element={<ConfidentialitePage />} />
  </>
);

const LogoutUserRoutes = () => {
  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/email" element={<LoginEmail />} />
      <Route path="/login/password" element={<LoginPassword />} />
      <Route path="/login/create-account" element={<LoginCreateAccount />} />
      <Route
        path="/challenges/:challengeId"
        element={<LogoutChallengePage />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
};

const AnonymousRoutes = () => {
  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route path="/" element={<Landing />} />
      <Route path="/login/email" element={<LoginEmail />} />
      <Route path="/login/password" element={<LoginPassword />} />
      <Route path="/login/create-account" element={<LoginCreateAccount />} />
      <Route path="/challenges/:challengeId" element={<ChallengePage />} />
      <Route
        path="/challenges/:challengeId/lessons/:lessonId/quizzes/:quizId/runs/:runId"
        element={<ChallengeLessonQuiz />}
      />
      <Route
        path="/challenges/:challengeId/lessons/:lessonId/quizzes/:quizId/runs/:runId/result"
        element={<AnonymousChallengeLessonQuizResult />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
};

const OnboardingParentRoutes = ({ user }: { user: User }) => {
  let fallbackRoute = '/onboarding';
  if (user.firstname || user.trial_started_at) {
    updateLocalStorageUserID(user.id ?? null);
    fallbackRoute = '/onboarding/parent/firstname';
  }

  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route path="/onboarding" element={<Onboarding />} />
      <Route
        path="/onboarding/parent/firstname"
        element={<OnboardingParentFirstname />}
      />
      <Route
        path="/onboarding/parent/child/:childId/firstname"
        element={<OnboardingParentChildFirstname />}
      />
      <Route
        path="/onboarding/parent/child/:childId/year"
        element={<OnboardingParentChildYear />}
      />
      <Route
        path="/onboarding/parent/child/:childId/gender"
        element={<OnboardingParentChildGender />}
      />
      <Route
        path="/onboarding/parent/child/:childId/school_level"
        element={<OnboardingParentChildSchoolLevel />}
      >
        <Route index element={null} />
        <Route
          path="device"
          element={
            <AutoFloatingPanel
              data-testid={'panel-onboarding-paywall'}
              isOpen={true}
              closable={false}
            >
              <Outlet />
            </AutoFloatingPanel>
          }
        >
          <Route index element={<OnboardingParentChildDevice />} />
          <Route path="other" element={<OnboardingParentChildOtherDevice />} />
          <Route path="paywall" element={<Outlet />}>
            <Route
              index
              element={<OnboardingParentChildPaywallDescription />}
            />
            <Route path="pay" element={<OnboardingParentChildPaywallPay />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={fallbackRoute} replace />} />
    </SentryRoutes>
  );
};

const OnboardingChildRoutes = () => {
  let fallbackRoute = '/onboarding/child/firstname';

  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route
        path="/onboarding/child/firstname"
        element={<OnboardingChildFirstname />}
      />
      <Route path="/onboarding/child/year" element={<OnboardingChildYear />} />
      <Route
        path="/onboarding/child/gender"
        element={<OnboardingChildGender />}
      />
      <Route
        path="/onboarding/child/school_level"
        element={<OnboardingChildSchoolLevel />}
      />
      <Route
        path="/onboarding/child/objective"
        element={<OnboardingChildObjective />}
      >
        <Route index element={null} />
        <Route
          path="paywall"
          element={
            <AutoFloatingPanel
              data-testid={'panel-onboarding-paywall'}
              isOpen={true}
              closable={false}
            >
              <Outlet />
            </AutoFloatingPanel>
          }
        >
          <Route index element={<OnboardingChildPaywallDescription />} />
        </Route>
      </Route>
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="*" element={<Navigate to={fallbackRoute} replace />} />
    </SentryRoutes>
  );
};

const UnknownUserRoutes = () => {
  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route path="*" element={<FamilyUserSelect />} />
    </SentryRoutes>
  );
};
const ParentRoutes = () => {
  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route path="/home" element={<HomeParentPage />} />
      <Route path="/profile" element={<ProfileParentPage />} />
      <Route
        path="/child/:childId/firstname"
        element={<ParentChildFirstname />}
      />
      <Route path="/child/:childId/year" element={<ParentChildYear />} />
      <Route path="/child/:childId/gender" element={<ParentChildGender />} />
      <Route
        path="/child/:childId/school_level"
        element={<ParentChildSchoolLevel />}
      >
        <Route index element={null} />
        <Route
          path="device"
          element={
            <AutoFloatingPanel
              data-testid={'panel-onboarding-paywall'}
              isOpen={true}
              closable={false}
            >
              <Outlet />
            </AutoFloatingPanel>
          }
        >
          <Route index element={<ParentChildDevicePage />} />
          <Route path="other" element={<ParentChildOtherDevicePage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </SentryRoutes>
  );
};
const ChildRoutes = () => {
  return (
    <SentryRoutes>
      {PublicRoutes}
      <Route path="/home" element={<Home />}>
        <Route index element={<HomeTab />} />
        <Route path="profile" element={<ProfileChildPage />} />
        <Route path="lessons" element={<LessonsPage />} />
        <Route path="notifications" element={<NotificationsPage />} />
      </Route>
      <Route path="/lessons/:lessonId" element={<Lesson />} />
      <Route path="/lessons/:lessonId/rename" element={<LessonRenamePage />} />
      <Route
        path="/lessons/:lessonId/revision"
        element={<LessonRevisionPage />}
      />
      <Route
        path="/lessons/:lessonId/improvements"
        element={<LessonImprovementsPage />}
      />
      <Route
        path="/lessons/:lessonId/progress"
        element={<LessonProgressPage />}
      />
      <Route path="/lessons/:lessonId/pieces/add" element={<AddPieces />} />
      <Route
        path="/lessons/:lessonId/pieces/photo"
        element={<LessonAddPhoto />}
      />
      <Route
        path="/lessons/:lessonId/pieces/audio"
        element={<LessonAddAudio />}
      />
      <Route
        path="/lessons/:lessonId/pieces/:pieceId/processing"
        element={<LessonPieceProcessing />}
      />
      <Route
        path="/lessons/:lessonId/quizzes/:quizId"
        element={<LessonQuiz />}
      />
      <Route
        path="/lessons/:lessonId/quizzes/:quizId/runs/:runId/result"
        element={<LessonQuizResult />}
      />
      <Route path="/challenges/:challengeId" element={<ChallengePage />} />
      <Route
        path="/challenges/:challengeId/lessons/:lessonId/quizzes/:quizId/runs/:runId"
        element={<ChallengeLessonQuiz />}
      />
      <Route
        path="/challenges/:challengeId/lessons/:lessonId/quizzes/:quizId/runs/:runId/result"
        element={<ChallengeLessonQuizResult />}
      />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </SentryRoutes>
  );
};

export const AppRouter = () => {
  const { data: user, isLoading } = useCurrentUser();
  const { isAnonymous } = useAuth();
  const familyUserId = getLocalStorageUserID();

  const location = useLocation();

  useEffect(() => {
    Modal.clear();
  }, [location]);

  useEffect(() => {
    Sentry.setUser({ id: user?.id });
    identifyUser(user);
    if (user) {
      loginOneSignal(user);
      logInAppPurchase(user);
      if (user.user_type === UserType.child) {
        updateLocalStorageUserID(user.id!);
      }
    } else {
      logOutInAppPurchase();
    }
  }, [user]);

  if (isLoading) {
    return <Spinner size="xl" className="w-full h-full" />;
  }
  if (!user) {
    return <LogoutUserRoutes />;
  }

  if (isAnonymous) {
    return <AnonymousRoutes />;
  }

  if (!user.onboarding_completed) {
    if (user.user_type === UserType.parent) {
      return <OnboardingParentRoutes user={user} />;
    }
    return <OnboardingChildRoutes />;
  }
  if (user.user_type === UserType.parent) {
    if (!familyUserId || familyUserId !== user.id) {
      return <UnknownUserRoutes />;
    }

    return (
      <>
        <ParentRoutes />
        <RedirectOnLogin />
      </>
    );
  }
  if (user.user_type === UserType.child) {
    return (
      <>
        <ChildRoutes />
        <RedirectOnLogin />
      </>
    );
  }

  return null;
};
