import { LocalStorageKey, milestoneTriggers } from 'src/utils/constants';
import {
  getInLocalStorageByKey,
  setInLocalStorage,
} from 'src/utils/storageManager';
import { MilestoneType } from 'src/types/milestone.types';

const getClosestMilestone = (milestone: MilestoneType, count: number) => {
  const triggers = milestoneTriggers[milestone];
  if (!triggers) {
    return undefined;
  }
  return triggers.findLast(trigger => trigger <= count);
};

export const shouldShowMilestone = (
  milestone: MilestoneType,
  currentCount: number,
  oldCount?: number,
) => {
  const triggers = milestoneTriggers[milestone];
  if (!triggers) {
    if (!oldCount) {
      return currentCount > 0;
    } else {
      return currentCount > oldCount;
    }
  }

  const closestMilestoneToTrigger = getClosestMilestone(
    milestone,
    currentCount,
  );
  if (!closestMilestoneToTrigger) {
    return false;
  }
  if (!oldCount) {
    return true;
  }
  if (currentCount <= oldCount) {
    return false;
  }
  if (!triggers) {
    return true;
  }

  const closestTriggeredMilestone = getClosestMilestone(milestone, oldCount);
  if (!closestTriggeredMilestone) {
    return false;
  }
  return closestMilestoneToTrigger > closestTriggeredMilestone;
};

export const updateLocalStorageMilestones = (
  familyUserId: number,
  milestone: MilestoneType,
  count: number,
) => {
  const localStorageMilestones = getInLocalStorageByKey(
    LocalStorageKey.MILESTONES,
  );
  const newLocalStorageMilestones = {
    ...localStorageMilestones,
    [familyUserId]: {
      ...localStorageMilestones?.[familyUserId],
      [milestone]: count,
    },
  };
  setInLocalStorage(LocalStorageKey.MILESTONES, newLocalStorageMilestones);
};
