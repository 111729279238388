import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { DatePickerView } from 'antd-mobile';
import { useNotification } from 'src/contexts/NotificationContext';
import { useUpdateLesson } from 'src/queries/lessons';
import dayjs from 'dayjs';
import Button from 'src/components/common/designSystem/Button';

const now = new Date();

const AddLessonEvaluationDate = ({
  isOpen,
  onClose,
  lessonId,
}: {
  isOpen: boolean;
  onClose: () => void;
  lessonId: number;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const { mutateAsync: updateLesson } = useUpdateLesson();
  const [date, setDate] = useState<Date>(now);

  const sendEvaluationDate = async () => {
    try {
      await updateLesson({
        id: lessonId,
        evaluation_date: dayjs(date).format('YYYY-MM-DD'),
      });
      onClose();
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t('lessons.evaluation.failToUpdate'),
      });
    }
  };

  return (
    <AutoFloatingPanel isOpen={isOpen} onClose={onClose}>
      <div className={'flex flex-col items-center justify-center gap-4 p-4'}>
        <div className={'text-base font-bold text-structural-darker'}>
          {t('lessons.evaluation.add')}
        </div>
        <DatePickerView
          defaultValue={now}
          mouseWheel
          value={date}
          onChange={setDate}
        />
        <div className={'flex flex-col gap-2 w-full'}>
          <Button
            type={'primary'}
            data-testid={'btn-continue'}
            onClick={sendEvaluationDate}
          >
            {t('global.continue')}
          </Button>
          <Button type={'text'} data-testid={'btn-skip'} onClick={onClose}>
            {t('onboarding.skip')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default AddLessonEvaluationDate;
