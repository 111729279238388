import { useCallback, useEffect, useState } from 'react';
import { getInLocalStorageByKey } from 'src/utils/storageManager';
import { LocalStorageKey } from 'src/utils/constants';
import {
  shouldShowMilestone,
  updateLocalStorageMilestones,
} from 'src/utils/milestones';
import { useGetMilestones } from 'src/queries/milestones';
import { getLocalStorageUserID } from 'src/utils/usersUtils';
import { MilestoneType } from 'src/types/milestone.types';

const useMilestones = ({
  trackedMilestones,
}: {
  trackedMilestones: MilestoneType[];
}) => {
  const { data: milestones } = useGetMilestones();
  const [milestoneToShow, setMilestoneToShow] = useState<{
    type: MilestoneType;
    count: number;
  }>();
  const familyUserId = getLocalStorageUserID();

  const updateLocalStorageMilestonesCB = useCallback(
    (milestone: MilestoneType, count: number) => {
      if (!familyUserId) return;
      updateLocalStorageMilestones(familyUserId, milestone, count);
    },
    [familyUserId],
  );

  const showMilestone = useCallback(() => {
    if (milestones === undefined || !familyUserId) return;

    const localStorageMilestones = getInLocalStorageByKey(
      LocalStorageKey.MILESTONES,
    );
    const oldUserMilestones = localStorageMilestones?.[familyUserId];

    for (const trackedMilestone of trackedMilestones) {
      const oldUserMilestone: number | undefined =
        oldUserMilestones?.[trackedMilestone];
      const currentUserMilestone = milestones[trackedMilestone];

      if (
        shouldShowMilestone(
          trackedMilestone,
          currentUserMilestone,
          oldUserMilestone,
        )
      ) {
        setMilestoneToShow({
          type: trackedMilestone,
          count: currentUserMilestone,
        });
        return;
      } else if (currentUserMilestone !== oldUserMilestone)
        updateLocalStorageMilestonesCB(trackedMilestone, currentUserMilestone);
    }
  }, [
    updateLocalStorageMilestonesCB,
    milestones,
    familyUserId,
    trackedMilestones,
  ]);

  useEffect(() => {
    showMilestone();
  }, [showMilestone]);

  const onClose = useCallback(() => {
    setMilestoneToShow(undefined);
    if (!milestones || !milestoneToShow) return;
    updateLocalStorageMilestonesCB(milestoneToShow.type, milestoneToShow.count);

    showMilestone();
  }, [
    milestones,
    milestoneToShow,
    updateLocalStorageMilestonesCB,
    showMilestone,
  ]);

  return {
    milestones,
    milestoneToShow,
    onClose,
  };
};

export default useMilestones;
