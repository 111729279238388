import { useTranslation } from 'react-i18next';

import ProfileMenu from 'src/components/Profile/ProfileMenu';

const ProfilePage = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col text-structural-darkest gap-6'}>
      <div className={'text-2xl font-bold text-structural-darkest my-4'}>
        {t('profile.title')}
      </div>
      <ProfileMenu />
    </div>
  );
};

export default ProfilePage;
