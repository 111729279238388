import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import Header from 'src/components/Header/Header';

export default function ConfidentialitePage() {
  return (
    <LayoutWithHeader header={<Header backArrow />}>
      <div className="p-4">
        <h1
          style={{
            paddingTop: '4pt',
          }}
        >
          Politique de Confidentialité de Baobab
        </h1>
        <br />
        <p>Dernière mise à jour: 04/06/2024</p>
        <br />
        <p
          className="s2"
          style={{
            lineHeight: '150%',
          }}
        >
          La présente charte sur le respect de la vie privée (la « Charte ») a
          pour objectif de formaliser notre engagement quant au respect de la
          vie privée des utilisateurs du site internet{' '}
          <a
            href="http://www.baobab-labs.com/"
            className="a"
            target="_blank"
            rel="noreferrer"
          >
            <span
              style={{
                color: '#1154CC',
                fontFamily: 'Arial, sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                textDecoration: 'underline',
                fontSize: '11pt',
              }}
            >
              www.baobab-labs.com
            </span>
          </a>
          <span style={{ color: '#000' }}>
            {' '}
            (le « Site ») et de l&#39;application mobile Baobab (l&#39;«
            Application »), exploités par la société Baobab Labs. La Charte et
            les Conditions Générales du Site forment un ensemble contractuel.
            Tous les termes en majuscules non définis dans la présente Charte
            sont définis dans les Conditions Générales consultables ici :
          </span>
        </p>
        <p>https://app.baobab-labs.com/terms-and-conditions</p>
        <br />
        <ol id="l1">
          <li data-list-text="1.">
            <h1
              style={{
                lineHeight: '150%',
              }}
            >
              Dans quels cas collectons-nous vos données à caractère personnel
              et quelles données sont collectées ?
            </h1>
            <p
              style={{
                paddingTop: '12pt',
                lineHeight: '150%',
              }}
            >
              Nous collectons et traitons des données à caractère personnel dans
              le cadre de la fourniture de nos Services ou de la communication à
              propos de ces Services, en stricte conformité avec le RGPD. Nous
              collectons uniquement des données à caractère personnel adéquates,
              pertinentes et limitées à ce qui est nécessaire au regard des
              finalités pour lesquelles elles sont traitées.
            </p>
            <h2
              style={{
                paddingTop: '12pt',
              }}
            >
              Données collectées
            </h2>
            <br />
            <ul id="l2">
              <li data-list-text="●">
                <p>Prénom du parent</p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Prénom de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Année de naissance de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Sexe de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Adresse e-mail du parent
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Numéro de téléphone de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Collège de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Classe de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Enregistrements audio des cours de l&#39;enfant
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Photos des cours de l&#39;enfant
                </p>
                <br />
                <p
                  style={{
                    lineHeight: '150%',
                  }}
                >
                  Nous sommes susceptibles de recueillir et de conserver vos
                  données à caractère personnel notamment lorsque vous :
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '4pt',
                  }}
                >
                  Naviguez sur le Site
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Utilisez l’application Baobab
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Créez un compte Baobab
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Nous contactez
                </p>
                <br />
              </li>
            </ul>
          </li>
          <li data-list-text="2.">
            <h1>Utilisation des Données</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Nous utilisons vos données à caractère personnel pour permettre la
              mise en œuvre et la gestion des Services et répondre à vos
              demandes spécifiques. Nous utilisons également vos données à
              caractère personnel dans le but d&#39;exploiter et d&#39;améliorer
              nos Services, notre Site et notre démarche. Ces informations sont
              utilisées uniquement par nos soins et nous permettent de mieux
              adapter nos Services à vos attentes.
            </p>
            <h2
              style={{
                paddingTop: '12pt',
              }}
            >
              Bases légales du traitement
            </h2>
            <br />
            <p>
              Nous traitons vos données personnelles sur la base des fondements
              juridiques suivants :
            </p>
            <br />
            <ul id="l3">
              <li data-list-text="●">
                <p>Votre consentement</p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  L&#39;exécution d&#39;un contrat
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                  }}
                >
                  Le respect d&#39;une obligation légale
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                    lineHeight: '150%',
                  }}
                >
                  Nos intérêts légitimes (par exemple, pour améliorer nos
                  services et sécuriser notre Application)
                </p>
                <br />
              </li>
            </ul>
          </li>
          <li data-list-text="3.">
            <h1>Transfert de Données</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Vos informations, y compris les données personnelles, peuvent être
              transférées vers des destinataires situés en dehors de
              l&#39;Espace Économique Européen (EEE) et stockées sur leurs
              serveurs. En utilisant notre Application, vous consentez à ces
              transferts. Tous les transferts de données à caractère personnel
              vers un pays situé hors de l’Union Européenne ont fait l’objet de
              conventions de flux transfrontière conformes aux clauses
              contractuelles types édictées par la Commission européenne.
            </p>
            <br />
          </li>
          <li data-list-text="4.">
            <h1>Partage des Données</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Nous pouvons partager vos données personnelles avec des tiers dans
              les circonstances suivantes :
            </p>
            <ul id="l4">
              <li data-list-text="●">
                <b>Prestataires de services </b>
                <span className="p">
                  : Nous pouvons employer des entreprises tierces pour faciliter
                  notre Application, fournir l&#39;Application en notre nom,
                  effectuer des services liés à l&#39;Application ou nous aider
                  à analyser la manière dont notre Application est utilisée.
                </span>
              </li>
              <li data-list-text="●">
                <b
                  style={{
                    lineHeight: '150%',
                  }}
                >
                  Obligations légales{' '}
                </b>
                <span className="p">
                  : Nous pouvons divulguer vos données personnelles lorsque cela
                  est requis par la loi ou en réponse à des demandes valides des
                  autorités publiques.
                </span>
              </li>
            </ul>
            <br />
          </li>
          <li data-list-text="5.">
            <h1>Sécurité des Données</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Nous mettons en œuvre des mesures de sécurité techniques et
              organisationnelles appropriées pour protéger vos données
              personnelles contre la perte, l&#39;utilisation abusive,
              l&#39;accès non autorisé, la divulgation, l&#39;altération ou la
              destruction. Toutefois, aucune mesure de sécurité n’étant
              infaillible, nous ne pouvons garantir une sécurité absolue.
            </p>
            <br />
          </li>
          <li data-list-text="6.">
            <h1>Conservation des Données</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Nous conservons vos données personnelles uniquement pendant la
              durée nécessaire aux fins décrites dans cette Politique de
              Confidentialité. Les critères utilisés pour déterminer la durée de
              conservation incluent la durée de notre relation avec vous et les
              obligations légales auxquelles nous sommes soumis.
            </p>
            <br />
          </li>
          <li data-list-text="7.">
            <h1>Cookies : Comment les utilisons-nous ?</h1>
            <br />
            <h2>Qu’est-ce qu’un cookie ?</h2>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Un cookie est un fichier texte susceptible d’être déposé dans un
              terminal lors de la consultation d’un service en ligne avec un
              logiciel de navigation. Un fichier cookie permet notamment à son
              émetteur, pendant sa durée de validité, de reconnaître le terminal
              concerné à chaque fois que ce terminal accède à un contenu
              numérique comportant des cookies du même émetteur.
            </p>
            <h2
              style={{
                paddingTop: '12pt',
              }}
            >
              A quoi servent les cookies émis sur notre Site ?
            </h2>
            <br />
            <p>Les cookies que nous émettons nous permettent :</p>
            <br />
            <ul id="l5">
              <li data-list-text="●">
                <p
                  style={{
                    lineHeight: '150%',
                  }}
                >
                  D&#39;établir des statistiques et volumes de fréquentation et
                  d&#39;utilisation des divers éléments composant notre Site
                </p>
              </li>
              <li data-list-text="●">
                <p>
                  D&#39;adapter la présentation de notre Site aux préférences
                  d&#39;affichage de votre terminal
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '6pt',
                    lineHeight: '150%',
                  }}
                >
                  De mémoriser des informations relatives à un formulaire que
                  vous avez rempli sur notre Site
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '4pt',
                  }}
                >
                  De vous permettre d&#39;accéder à des espaces réservés et
                  personnels de notre Site
                </p>
                <br />
                <h2>Comment pouvez-vous contrôler les cookies utilisés ?</h2>
                <br />
                <p
                  style={{
                    lineHeight: '150%',
                  }}
                >
                  Vous pouvez à tout moment configurer votre logiciel de
                  navigation de manière à ce que des cookies soient enregistrés
                  dans votre terminal ou au contraire qu&#39;ils soient rejetés.
                  Tout paramétrage est susceptible de modifier votre navigation
                  sur Internet et vos conditions d&#39;accès à certains services
                  nécessitant l&#39;utilisation de cookies.
                </p>
                <br />
              </li>
            </ul>
          </li>
          <li data-list-text="8.">
            <h1>Vos Droits</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Vous disposez de droits sur vos données à caractère personnel.
              Conformément à la réglementation en matière de protection des
              données à caractère personnel, notamment les articles 15 à 22 du
              RGPD, et après avoir justifié de votre identité, vous avez le
              droit de nous demander l&#39;accès aux données à caractère
              personnel vous concernant, la rectification ou l&#39;effacement de
              celles-ci.
            </p>
            <p
              style={{
                paddingTop: '12pt',
              }}
            >
              Vous pouvez exercer ces droits en nous contactant à l&#39;adresse
              e-mail fournie ci-dessous.
            </p>
            <br />
          </li>
          <li data-list-text="9.">
            <h1>Modifications de cette Politique de Confidentialité</h1>
            <br />
            <p
              style={{
                lineHeight: '150%',
              }}
            >
              Nous pouvons mettre à jour notre Politique de Confidentialité de
              temps à autre. Nous vous informerons de toute modification en
              publiant la nouvelle Politique de Confidentialité sur cette page.
              Nous vous conseillons de consulter cette Politique de
              Confidentialité périodiquement pour prendre connaissance de toute
              modification.
            </p>
            <br />
          </li>
          <li data-list-text="10.">
            <h1>
              La Commission Nationale de l&#39;Informatique et des Libertés
              (&quot;CNIL&quot;)
            </h1>
          </li>
        </ol>
        <br />
        <p
          style={{
            lineHeight: '150%',
          }}
        >
          Nous vous rappelons que vous pouvez contacter la CNIL directement sur
          le site internet de la CNIL ou par courrier à l’adresse suivante :
          Commission Nationale de l&#39;Informatique et des Libertés (CNIL), 3
          Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
        </p>
      </div>
    </LayoutWithHeader>
  );
}
