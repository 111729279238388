import { Link, useSearchParams } from 'react-router-dom';
import LogoMonkey from 'src/images/logo-monkey.svg?react';
import Button from 'src/components/common/designSystem/Button';
import { Trans, useTranslation } from 'react-i18next';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';

function Home() {
  const { t } = useTranslation();
  const [searchParams, _] = useSearchParams();
  const loginRoute = {
    pathname: '/login',
    searchParams: searchParams.toString(),
  };

  return (
    <BlankPrimaryLayout>
      <div
        className={
          'flex flex-col items-center justify-between w-full h-full py-6'
        }
      >
        <div className={'flex-1 flex items-center justify-center'}>
          <LogoMonkey className="h-60" />
        </div>
        <div className={'flex flex-col items-center w-full gap-3 p-6'}>
          <Link to={loginRoute} className="w-full">
            <Button
              data-testid="btn-letsgo"
              type={'primary'}
              className="w-full"
            >
              {t('global.letsGo')}
            </Button>
          </Link>
          <div className={'text-center text-sm text-white text-balance w-full'}>
            <Trans i18nKey={'landing.footer'}>
              En continuant, j’accepte les{' '}
              <Link
                to={'/terms-and-conditions'}
                className={'font-extrabold text-white'}
              >
                conditions générales d’utilisation
              </Link>{' '}
              et{' '}
              <Link
                to={'/confidentialite'}
                className={'font-extrabold text-white'}
              >
                politiques de confidentialité
              </Link>
            </Trans>
          </div>
        </div>
      </div>
    </BlankPrimaryLayout>
  );
}
export default Home;
