import CheckIcon from 'src/images/check.svg?react';

const PaywallCheckIcon = () => {
  return (
    <div
      className={
        'w-5 h-5 flex items-center justify-center rounded-full bg-gradient-to-r from-paywall-darkest-from to-paywall-darkest-to'
      }
    >
      <CheckIcon className={'fill-white w-2'} />
    </div>
  );
};

export default PaywallCheckIcon;
