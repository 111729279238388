import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { Capacitor } from '@capacitor/core';
import { addUserProperty } from 'src/modules/analytics';

const AppUrlListener: FC<any> = () => {
  let navigate = useNavigate();

  const processUrl = useCallback(
    (url: string) => {
      // Example url: https://app.baobab-labs.com/tabs/tab2
      // slug = /tabs/tab2
      const slug = url.split('.baobab-labs.com').pop();
      if (slug) {
        navigate(slug);
      }
    },
    [navigate],
  );

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      processUrl(event.url);
    });

    if (!Capacitor.isNativePlatform()) {
      return;
    }

    BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
      const url = event.referringParams['$canonical_url'];
      const utm_medium = event.referringParams['~feature'];
      const utm_source = event.referringParams['~channel'];
      const utm_campaign = event.referringParams['~campaign'];
      const tags = event.referringParams['~tags'];

      // track utm params in amplitude
      addUserProperty('initial_utm_medium', utm_medium);
      addUserProperty('initial_utm_source', utm_source);
      addUserProperty('initial_utm_campaign', utm_campaign);
      if (tags) {
        addUserProperty('initial_tags', tags.join(','));
      }

      if (url) {
        processUrl(url);
      }
    });

    BranchDeepLinks.addListener('initError', (error: any) => {
      console.error(error);
    });
  }, [processUrl]);

  return null;
};

export default AppUrlListener;
