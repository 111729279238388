import { type Dayjs } from 'dayjs';
import Flame from 'src/images/Flame.svg?react';
import CheckBaobabIcon from 'src/images/check-baobab.svg?react';

const StreakDay = ({
  day,
  isCompleted,
  size = 'medium',
  withFlame = false,
}: {
  day: Dayjs;
  size?: 'small' | 'medium';
  isCompleted: boolean;
  withFlame?: boolean;
}) => {
  const dayLetter = day.locale('fr').format('ddd')[0].toUpperCase();
  return (
    <div className={'flex flex-col items-center justify-center gap-1'}>
      <div
        className={
          size === 'small'
            ? 'h5'
            : 'font-extrabold text-xs text-structural-neutral'
        }
      >
        {dayLetter}
      </div>
      {isCompleted ? (
        withFlame ? (
          <StreakDayFlame />
        ) : (
          <StreakDayCompleted
            className={size === 'small' ? 'w-6 h-6' : 'w-5 h-5'}
          />
        )
      ) : (
        <StreakDayNotCompleted
          className={size === 'small' ? 'w-6 h-6' : 'w-5 h-5'}
        />
      )}
    </div>
  );
};

export const StreakDayNotCompleted = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <div
      className={
        'flex items-center justify-center rounded-full border-2 border-structural-darkest ' +
        className
      }
    />
  );
};
export const StreakDayCompleted = ({ className }: { className?: string }) => {
  return (
    <div
      data-testid={'streak-day-completed'}
      className={'flex items-center justify-center rounded-full ' + className}
    >
      <CheckBaobabIcon className={'w-full h-full text-white'} />
    </div>
  );
};
export const StreakDayFlame = () => {
  return <Flame className={'w-6 h-6'} />;
};

export default StreakDay;
