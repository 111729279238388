import { useTranslation } from 'react-i18next';

import { getSubjectInfo } from 'src/utils/subject';
import QuizScore from 'src/components/Quiz/QuizScore';
import { Lesson } from 'src/types/lesson.types';

export default function LessonParentCard({ lesson }: { lesson: Lesson }) {
  const { t } = useTranslation();
  const subject = getSubjectInfo(lesson.subject_matter);

  const runs = lesson.latest_quiz?.runs;
  const maxScore = runs?.length
    ? Math.max(...runs.map(run => run.grade))
    : undefined;

  return (
    <div
      className={
        'flex flex-row items-center px-5 py-3.5 w-full gap-4 cursor-pointer bg-white rounded-3xl'
      }
      data-testid={`card-lesson-${lesson.id}`}
    >
      <div>
        <div
          className={
            'w-14 h-14 relative overflow-hidden bg-primary-neutral rounded-2xl flex items-center justify-center p-3'
          }
        >
          <subject.IconComponent className={'w-full h-full'} />
        </div>
      </div>
      <div className={'flex flex-col flex-grow overflow-hidden'}>
        <div
          className={
            'text-base overflow-ellipsis line-clamp-2 w-full overflow-hidden font-bold text-structural-darkest'
          }
        >
          {lesson.title}
        </div>
        <div className={'flex flex-row justify-between items-center gap-2'}>
          <div className={'text-structural-dark'}>{t(subject.labelKey)}</div>
          {maxScore ? <QuizScore score={maxScore} /> : null}
        </div>
      </div>
    </div>
  );
}
