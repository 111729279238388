import SettingsIcon from 'src/images/settings.svg?react';
import { useNavigate } from 'react-router-dom';

const ProfileButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className={'p-4'}
      data-testid="btn-profile"
      onClick={() => {
        navigate('/profile');
      }}
    >
      <SettingsIcon className={'w-6 h-6'} />
    </div>
  );
};

export default ProfileButton;
