export default {
  title: 'Fiches de révisions',
  tabs: {
    all: 'Voir tout',
    toRevise: 'À réviser',
    evaluations: 'Évaluations',
  },
  card: {
    pieces_one: '{{count}} partie',
    pieces_other: '{{count}} parties',
    quiz: '{{count}} quiz',
    revise: 'Réviser',
  },
  status: {
    noQuiz: 'Fais ton premier quiz !',
    toRevise: 'À réviser',
    ok: 'Pas mal !',
    good: "C'est bien !",
    perfect: 'Excellent !',
    processing: 'Quiz en cours de préparation...',
    quizReady: 'Ton quiz est prêt !',
    ready: 'C’EST PRÊT !',
  },
  empty: {
    title: 'Tu n’as pas de révisions en cours',
    description: 'Ajoute dès maintenant ta première leçon !',
  },
  evaluation: {
    in: 'Évaluation {{time}}',
    daysIn: 'J-{{days}}',
    daysInPassed: 'J+{{days}}',
    add: 'Saisis ta date d’évaluation',
    passed: 'Évaluation passée',
    failToUpdate: "Impossible de mettre à jour la date d'évaluation",
  },
  activity: {
    questions: 'Activité',
    success: 'Succès',
  },
  details: {
    activity: 'Mon activité',
    lastScore: 'Dernier score',
    nextEvaluation: 'Prochaine évaluation',
    tools: 'Mes outils de révision',
    revision: 'Fiche de révision',
    improvements: 'Axes d’amélioration',
    progress: 'Ma progression',
  },
  improvements: {
    errors_one: '{{count}} erreur',
    errors_other: '{{count}} erreurs',
    noErrors: {
      title: 'Tu es un.e vrai.e génie !',
      description:
        'Impressionnant ! Tu connais ta leçon sur le bout des doigts.',
    },
    noQuiz: {
      title: 'Tu n’as pas encore complété ton quiz...',
      description: 'Lance toi et suis tes progrès avant ta date d’évaluation !',
    },
  },
  progress: {
    bestScore: 'Meilleur score',
    average: 'Moyenne',
  },
  menu: {
    rename: 'Renommer',
    delete: 'Supprimer',
  },
  delete: {
    title: 'Es-tu sûr de supprimer cette leçon ?',
    description: 'Une fois supprimée, ta leçon ne sera plus disponible',
    error: 'Impossible de supprimer la leçon',
  },
  addLesson: 'Ajouter une leçon',
  lastLessons: 'Mes dernières leçons',
  noLessons:
    "Il n'y a pas encore de leçons.\nEnregistres en une pour commencer à réviser !",
  lessonsToRevise: 'Leçons à réviser',
  noLessonsToRevise:
    "Il n'y a pas de leçon à réviser. Enregistres en une pour commencer !",
  noSmartbits: "Il n'y a pas encore de point à retenir pour cette leçon",
  toRememberFromThisLesson: '📚 A retenir de cette leçon :',
  magicVoiceTitle: 'Appuie sur le bouton micro et lis ta leçon à haute voix 🎙',
  magicVoiceDescription:
    'Une fois enregistrée, nous créerons ta fiche de révision et ton quiz pour préparer ton évaluation !',
  lessonPieceNumbered: '👉 Partie {{number}}',
  startAQuiz: 'Commencer un quiz',
  addContent: 'Ajouter du contenu',
  createMyLesson: 'Créer ma leçon',
  completeMyLesson: 'Completer ma leçon',
  processing:
    'Ta fiche de révision est en cours de fabrication ! Cela devrait prendre quelques minutes, reviens dès que c’est prêt !',
  addAPiece: 'Ajouter une partie',
  new: {
    title: {
      title: 'Quel est le titre de ta leçon ?',
      placeholder: {
        history: "L'Empire romain",
        geography: 'Les climats et saisons',
        english: 'Les jours et les mois',
        spanish: 'Les jours de la semaine',
        french: 'Les figures de style',
        mathematics: 'Les fractions',
        sciences: 'Le corps humain',
        physics: 'Les forces et mouvements',
      },
      required: 'Le titre est obligatoire.',
    },
    lessonTitle: 'Quel est le titre de ta leçon ?',
    lessonTitlePlaceholder: "Exemple: L'Empire Byzantin",
    lessonTitleRequired: 'Le titre est obligatoire.',
    create: 'Commencer',
  },
  newPiece: {
    titlePieceType: 'Comment veux-tu partager ce que tu as appris ?',
    titlePieceTypePhoto: '📷  Ajouter une photo',
    titlePieceTypeAudio: '🎤  Ajouter un audio',
    titlePlaceholder: "Comment s'appelle cette partie ?",
  },
  smartbit: {
    objective: 'Objectif :',
    reviewQuestions: "Questions d'exemple :",
    result: {
      description:
        'Tu as revu toutes tes fiches de révisions. Tu es prête pour faire un quiz!',
    },
  },
  audio: {
    title: 'Lis-moi ta leçon',
    description: 'Appuie sur le bouton micro et lis ta leçon à haute voix 🎙',
    error: 'Impossible de charger l’audio',
  },
  photo: {
    title: 'Prends ta leçon en photo',
    error: 'Impossible de charger la photo',
    permission: {
      camera: {
        not_granted: {
          title: 'Permission refusée',
          description:
            'Pour continuer, tu dois autoriser l’accès à ta caméra dans les paramètres de ton téléphone.',
        },
      },
      gallery: {
        not_granted: {
          title: 'Permission refusée',
          description:
            'Pour continuer, tu dois autoriser l’accès à ta gallerie dans les paramètres de ton téléphone.',
        },
      },
    },
    another: {
      title: 'Une autre photo à ajouter ?',
      description: 'Tu peux ajouter d’autres pages de ta leçon en photos !',
    },
  },
  piece: {
    processing: {
      title: 'J’organise ta leçon...',
      description:
        'Plus que quelques instants... Nous créons ta fiche de révision et ton quiz personnalisé !',
    },
    error: {
      title: 'Oups ! Une erreur est survenue...',
      audio: {
        description:
          'Recommence en t’assurant que ton contenu soit bien audible lorsque tu enregistres ta leçon.',
      },
      photo: {
        description:
          'Recommence en t’assurant que ton contenu soit bien visible lorsque tu prends ta photo.',
      },
    },
    quiz: {
      title: 'Ta révision est prête !',
      description:
        'Prêt(e) à relever le défi ? Révise les notions de ta leçon en t’amusant !',
      start: 'Lancer le quiz',
    },
  },
};
