import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LessonCardIcon from 'src/components/LessonsLists/LessonCardIcon';
import { subjects } from 'src/utils/constants';
import LessonCardHeader from 'src/components/LessonsLists/LessonCardHeader';
import LessonCardXP from 'src/components/LessonsLists/LessonCardXP';
import { Lesson } from 'src/types/lesson.types';

export default function LessonCard({ lesson }: { lesson: Lesson }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/lessons/${lesson.id}`);
  };

  return (
    <div
      className={
        'bg-gradient-to-b from-[#B358DC] to-[#9B5FFF] cursor-pointer rounded-2xl overflow-hidden'
      }
    >
      <div
        className={
          'flex flex-col h-[414px] justify-center items-center ' +
          'bg-gradient-to-b from-50% from-[#00000000] to-[#000000bb] text-white w-full'
        }
        onClick={handleClick}
        data-testid={`card-lesson-${lesson.id}`}
      >
        <div
          className="flex flex-1 flex-col items-start w-full gap-3 p-4"
          style={{
            backgroundImage: `url(bg-pattern.svg)`,
            backgroundRepeat: 'repeat',
          }}
        >
          <LessonCardHeader lesson={lesson} />
          <div className={'flex-1 w-full flex items-center justify-center'}>
            <LessonCardIcon lesson={lesson} />
          </div>
          <LessonCardXP lesson={lesson} />
          <div
            className={
              'text-3xl line-clamp-2 font-bold text-ellipsis overflow-hidden w-full'
            }
          >
            {lesson.title}
          </div>
          <div className={'flex flex-row justify-between items-center w-full'}>
            <div className={'text-base font-bold opacity-70'}>
              {t(subjects[lesson.subject_matter].labelKey)}
            </div>
            <div
              className={
                'bg-white rounded-full px-4 py-1 font-semibold text-structural-darkest'
              }
            >
              {t('lessons.card.revise')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
