export default {
  title: 'Notifications',
  empty: {
    title: 'Une évaluation à venir ?',
    description: 'Révise avec un quiz',
  },
  quiz_ready: {
    title: 'Ta révision est prête !',
    button: 'Démarrer',
  },
  challenge_finished: {
    win: 'Tu as gagné le duel !',
    lose: '{{opponentFirstName}} a gagné le duel !',
    equal: 'Egalité avec {{opponentFirstName}} !',
  },
};
