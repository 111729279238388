import { useTranslation } from 'react-i18next';
import AddLessonButton from 'src/components/AddLesson/AddLessonButton';
import BaoWink from 'src/images/bao/bao-wink.svg?react';
import BackgroundPattern from 'src/components/common/BackgroundPattern';

const CreateFirstLessonCompletionCard = () => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={'card-create-lesson'}
      className={
        'flex flex-col relative items-center overflow-hidden rounded-2xl bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to text-white'
      }
    >
      <div
        className={
          'flex flex-col py-8 gap-2 items-center ' +
          'bg-gradient-to-b from-50% from-[#00000000] to-[#000000bb] w-full'
        }
      >
        <BackgroundPattern />
        <BaoWink className={'w-36 h-auto'} />
        <div className={'flex flex-col gap-6 px-8 items-start'}>
          <div className={'font-bold text-3xl'}>
            {t('home.completionCard.createLesson')}
          </div>
          <AddLessonButton />
        </div>
      </div>
    </div>
  );
};

export default CreateFirstLessonCompletionCard;
