import QuizStarsIcon from 'src/components/Quiz/QuizStarsIcon';

const QuizAnswer = ({
  isSelected,
  isCorrect,
  showCorrection,
  onClick,
  id,
  index,
}: {
  showCorrection: boolean;
  onClick?: () => void;
  isSelected: boolean;
  isCorrect: boolean;
  id: string;
  index: number;
}) => {
  const getAnswerColor = () => {
    if (showCorrection) {
      if (isCorrect) {
        return 'bg-secondary-lighter border-secondary-lighter';
      } else if (isSelected) {
        return 'bg-red-lightest border-red-lightest';
      }
      return 'bg-white border-secondary-lighter opacity-20';
    }
    if (isSelected) {
      return 'bg-secondary-lighter border-secondary-lighter';
    }
    return 'bg-white border-secondary-lighter';
  };

  const getLetterByIndex = (index: number) => {
    const letter = String.fromCharCode(65 + index);
    if (showCorrection) {
      if (isCorrect) {
        return (
          <span>
            <span className={'mr-3'}>✅</span>
            <span>{letter}</span>
          </span>
        );
      }
      if (isSelected) {
        return (
          <span>
            <span className={'mr-3'}>❌</span>
            <span>{letter}</span>
          </span>
        );
      }
    }
    return letter;
  };

  return (
    <div
      data-testid={`answer-${id}`}
      onClick={onClick}
      className={`flex items-center relative text-base text-balance gap-4 text-structural-darker font-extrabold text-left justify-between cursor-pointer px-4 h-12 rounded-lg border ${getAnswerColor()}`}
    >
      <div
        className={'overflow-ellipsis whitespace-nowrap w-full overflow-hidden'}
      >
        {id}
      </div>
      <div>{getLetterByIndex(index)}</div>
      {isCorrect && isSelected && showCorrection ? <QuizStarsIcon /> : null}
    </div>
  );
};

export default QuizAnswer;
