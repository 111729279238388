import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useNotification } from 'src/contexts/NotificationContext';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

export default function FirebaseAuthActions() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showSuccess } = useNotification();

  useEffect(() => {
    const oobCode = searchParams.get('oobCode');
    try {
      if (oobCode) {
        if (searchParams.get('mode') === 'resetPassword') {
          const oobCode = searchParams.get('oobCode');
          navigate({
            pathname: '/reset_password',
            search: createSearchParams({
              oobCode: oobCode!,
            }).toString(),
          });
          return;
        }
        if (searchParams.get('mode') === 'verifyEmail') {
          FirebaseAuthentication.applyActionCode({
            oobCode,
          })
            .then(() => {
              showSuccess({
                message: t('login.emailIsVerified'),
              });
              navigate('/');
            })
            .catch(() => {
              navigate('/');
            });
          return;
        }
      }
      navigate('/');
    } catch {
      navigate('/');
    }
  });

  return <Spinner size="xl" className="w-full h-full" />;
}
