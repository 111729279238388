import PlusIcon from 'src/images/plus.svg?react';
import { SafeArea } from 'antd-mobile';
import { useCreateUser } from 'src/queries/users';
import { useNavigate } from 'react-router-dom';
import { useNotification } from 'src/contexts/NotificationContext';
import { logAnalyticsEvent } from 'src/modules/analytics';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';
import { UserType } from 'src/types/user.types';

const AddChildFloatingButton = () => {
  const { t } = useTranslation();
  const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser();
  const navigate = useNavigate();
  const { showError } = useNotification();

  const onClick = async () => {
    if (isCreating) {
      return;
    }
    try {
      const createdChild = await createUser({
        user_type: UserType.child,
      });
      logAnalyticsEvent('add_child_button_clicked');
      navigate(`/child/${createdChild.id}/firstname`);
    } catch (error) {
      console.error(error);
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  return (
    <>
      <div className={'fixed bottom-4 right-4'} data-testid={'btn-add-child'}>
        <div
          onClick={onClick}
          className={
            'flex items-center justify-center rounded-full w-16 h-16 bg-structural-darkest'
          }
        >
          {isCreating ? (
            <Spinner type={'white'} size={'small'} />
          ) : (
            <PlusIcon className={'fill-white w-4 h-4'} />
          )}
        </div>
        <SafeArea position={'bottom'} />
      </div>
    </>
  );
};

export default AddChildFloatingButton;
