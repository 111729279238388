import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Tabs as AntdTabs,
  type TabsProps,
  type TabProps,
  type SwiperRef,
  Swiper,
} from 'antd-mobile';

const Tabs = (
  props: Omit<TabsProps, 'children'> & {
    children: Array<ReactElement<TabProps>>;
    'data-testid'?: string;
  },
) => {
  const swiperRef = useRef<SwiperRef>(null);
  const tabRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { children, 'data-testid': dataTestId } = props;

  useEffect(() => {
    swiperRef.current?.swipeTo(activeIndex);
    if (!tabRef.current) {
      return;
    }
    const tab = tabRef.current.children[activeIndex] as HTMLElement;
    tab.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [activeIndex]);

  return (
    <>
      <div
        className={'flex flex-row items-center w-full sticky top-0 z-10'}
        data-testid={dataTestId}
      >
        <div
          className={
            'flex flex-row gap-2 px-4 items-center overflow-auto w-full'
          }
          ref={tabRef}
          data-testid="tabs"
        >
          {children.map((child, index) => (
            <div
              key={child.key}
              data-testid={`tab-${child.key}`}
              className={`px-4 h-9 flex items-center justify-center whitespace-nowrap font-bold bg-structural-darkest rounded-full ${
                index === activeIndex
                  ? 'bg-opacity-100 text-white'
                  : 'bg-opacity-10 text-structural-darkest'
              }`}
              onClick={() => {
                setActiveIndex(index);
              }}
            >
              <span>{child.props.title}</span>
            </div>
          ))}
        </div>
      </div>

      <Swiper
        direction="horizontal"
        className="flex-grow"
        indicator={() => null}
        ref={swiperRef}
        defaultIndex={activeIndex}
        onIndexChange={index => {
          setActiveIndex(index);
        }}
      >
        {children
          .filter(item => !item.props.disabled)
          .map(item => (
            <Swiper.Item key={item.key} data-testid={`tab-content-${item.key}`}>
              {item.props.children}
            </Swiper.Item>
          ))}
      </Swiper>
    </>
  );
};
const Tab = AntdTabs.Tab;

export { Tabs, Tab };
