import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { InputPassword } from 'src/components/common/designSystem/Input';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { AuthError, authErrorCodeToMessage } from 'src/modules/firebase';
import { useNotification } from 'src/contexts/NotificationContext';
import { useAuth } from 'src/hooks/useAuth';
import { AuthMode } from 'src/contexts/AuthContext';
import { Form } from 'antd';
import BlankLayout from 'src/layouts/BlankLayout';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';

function LoginPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showError } = useNotification();
  const { setMode } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  useEffect(() => {
    if (!email) {
      navigate('/', { replace: true });
    }
  }, [email, navigate]);

  const onLogin = async ({ password }: { password: string }) => {
    if (!email) {
      return;
    }
    setIsLoading(true);
    try {
      setMode(AuthMode.LOGIN);
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
    } catch (error) {
      const message = authErrorCodeToMessage(error as AuthError);
      if (message) {
        console.error(error);
        showError({
          message: message,
          error,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <BlankLayout>
      <BackArrowHeader />
      <Form
        name="passwordForm"
        onFinish={onLogin}
        className="flex-1 bg-transparent w-full flex flex-col justify-between items-center px-4 gap-4"
        disabled={isLoading}
      >
        <div className={'font-extrabold text-base text-balance text-center'}>
          {t('login.title')}
        </div>
        <div className="w-full">
          <Form.Item
            name="password"
            validateTrigger="onBlur"
            className="bg-transparent w-full"
            rules={[
              {
                required: true,
                message: t('global.validation.fieldRequired'),
              },
            ]}
          >
            <InputPassword
              autoComplete="current-password"
              data-testid="inp-password"
              className="w-full"
              prefix="🔒"
              placeholder={t('login.passwordLabel')}
            />
          </Form.Item>
          <Link
            className="flex-1 flex justify-center items-end text-lg"
            to={{
              pathname: '/reset_password',
              search: searchParams.toString(),
            }}
          >
            <Button type="link" data-testid="btn-forgot-password">
              {t('login.resetPassword.goToForgotPassword')}
            </Button>
          </Link>
        </div>
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="passwordForm"
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {t('global.continue')}
        </Button>
      </Footer>
    </BlankLayout>
  );
}

export default LoginPassword;
