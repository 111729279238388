import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useNotification } from 'src/contexts/NotificationContext';
import { AuthError, authErrorCodeToMessage } from 'src/modules/firebase';
import { useAuth } from 'src/hooks/useAuth';
import { AuthMode } from 'src/contexts/AuthContext';

const LoginWithGoogleButton = ({ onLogin }: { onLogin?: () => void }) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const { setMode } = useAuth();

  const loginWithGoogle = async () => {
    try {
      setMode(AuthMode.SIGNUP);
      await FirebaseAuthentication.signInWithGoogle({
        customParameters: [
          {
            key: 'prompt',
            value: 'select_account',
          },
        ],
        mode: 'popup',
      });
      onLogin && onLogin();
    } catch (error) {
      const message = authErrorCodeToMessage(error as AuthError);
      if (message) {
        console.error(error);
        showError({
          message: message,
          error,
        });
      }
    }
  };

  return (
    <Button
      data-testid={'btn-login-google'}
      icon={<IconLogosGoogleIcon />}
      onClick={loginWithGoogle}
    >
      {t('login.withGoogle')}
    </Button>
  );
};

export default LoginWithGoogleButton;
