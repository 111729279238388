import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserItem from 'src/components/UserManagement/UserItem';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useGetUsers } from 'src/queries/users';
import { useSwitchUser } from 'src/queries/sessions';
import Title from 'src/components/common/designSystem/Title';
import LayoutWithHeader from '../layouts/LayoutWithHeader';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';

const FamilyUserSelect = () => {
  const { t } = useTranslation();
  const [switching, setSwitching] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const { data: users, isLoading } = useGetUsers();
  const { mutateAsync: switchUser } = useSwitchUser();

  const handleSwitch = async () => {
    if (!selectedUserId) return;

    setSwitching(true);
    await switchUser(selectedUserId);
    setSwitching(false);
  };

  return (
    <LayoutWithHeader>
      <div className="flex-1 flex flex-col items-center w-full">
        <Title>{t('login.familyUserSelect.welcomeWhoAreYou')}</Title>
        <div className="flex-1 flex flex-col justify-center gap-3 w-full">
          {isLoading ? (
            <Spinner className="w-full" />
          ) : (
            users?.map(user => {
              return (
                <UserItem
                  key={user.id}
                  user={user}
                  className={
                    selectedUserId === user.id ? '!bg-primary-light' : ''
                  }
                  icon={
                    selectedUserId === user.id && (
                      <IconMdiCheckCircle className="text-primary-darkest" />
                    )
                  }
                  onClick={() => {
                    setSelectedUserId(user.id!);
                  }}
                />
              );
            })
          )}
        </div>
      </div>
      <Footer>
        <Button
          data-testid="btn-select-user"
          type="primary"
          disabled={!selectedUserId}
          loading={switching}
          onClick={handleSwitch}
        >
          {t('login.familyUserSelect.letsGo')}
        </Button>
      </Footer>
    </LayoutWithHeader>
  );
};

export default FamilyUserSelect;
