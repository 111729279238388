import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import pageViewTrackingEnrichment from 'src/utils/PageViewTrackingEnrichment';
import { User } from 'src/types/user.types';

type AnalyticsEvents =
  | 'add_child_button_clicked'
  | 'quiz_started'
  | 'quiz_finished'
  | 'quiz_answered'
  | 'lesson_created'
  | 'lesson_piece_created'
  | 'invite_user'
  | 'signup'
  | 'signin'
  | 'onboarding_complete'
  | 'paywall_viewed'
  | 'trial_started'
  | 'trial_ended'
  | 'add_lesson-content'
  | 'start_quiz'
  | 'complete_quiz'
  | 'quit_quiz'
  | 'challenge_friend'
  | 'challenge_accepted'
  | 'button_click';

export function logAnalyticsEvent(
  eventName: AnalyticsEvents,
  eventParams?: Record<string, any>,
): void {
  if (import.meta.env.VITE_CI) {
    return;
  }

  amplitude.track(eventName, eventParams);
}

export const initAnalytics = async () => {
  if (import.meta.env.VITE_CI) {
    return;
  }

  amplitude.add(pageViewTrackingEnrichment());
  if (Capacitor.isNativePlatform()) {
    const currentBuild = await CapacitorUpdater.current();
    amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
      minIdLength: 1,
      appVersion: currentBuild.bundle.version,
      autocapture: true,
    });
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1,
    });
    amplitude.add(sessionReplayTracking);
    amplitude.setGroup('native_version', currentBuild.native);
  } else {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
      minIdLength: 1,
      autocapture: true,
    });
  }
  amplitude.setGroup('device_platform', Capacitor.getPlatform());
};

export const identifyUser = (user?: User) => {
  if (import.meta.env.VITE_CI) {
    return;
  }

  if (!user || !user.id) {
    return;
  }

  amplitude.setUserId(String(user.id));
  addUserProperty('familyId', String(user.family_id));
  addUserProperty('userType', user.user_type);
  addUserProperty('yob', dayjs(user.date_of_birth).year());
  addUserProperty('grade_level', user.school_level);
  addUserProperty('school_id', user.school_id);
  addUserProperty('objectives', user.objectives);
};

export const addUserProperty = (key: string, value: any) => {
  if (import.meta.env.VITE_CI) {
    return;
  }

  amplitude.setGroup(key, value);
};

export const getAnalyticsDeviceId = () => {
  if (import.meta.env.VITE_CI) {
    return undefined;
  }

  return amplitude.getDeviceId();
};
