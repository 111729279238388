import { useLesson } from 'src/components/LessonDetail/LessonContext';
import BackgroundPattern from 'src/components/common/BackgroundPattern';
import { getSubjectInfo } from 'src/utils/subject';
import LessonHeaderMenu from 'src/components/LessonDetail/LessonHeaderMenu';
import { LeftOutline } from 'antd-mobile-icons';
import useNavigateBack from 'src/hooks/useNavigateBack';
import LessonEvaluation from 'src/components/LessonDetail/LessonEvaluation';
import { SafeArea } from 'antd-mobile';

const LessonHeader = () => {
  const { lesson } = useLesson();
  const lessonSubject = getSubjectInfo(lesson.subject_matter);
  const goBack = useNavigateBack();

  return (
    <div
      data-testid={'lesson-header'}
      className={
        'flex flex-col relative overflow-hidden min-h-52 items-center bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to text-white'
      }
    >
      <BackgroundPattern />
      <div
        className={
          'absolute z-0 inset-0 flex justify-center items-center pt-6 pb-16'
        }
      >
        <lessonSubject.IconComponent className={'w-auto h-full'} />
      </div>
      <SafeArea position="top" />
      <div
        className={
          'flex flex-1 z-10 flex-col px-5 py-4 gap-2 items-start justify-between ' +
          'bg-gradient-to-b from-50% from-[#00000000] to-[#000000bb] w-full'
        }
      >
        <div className={'flex w-full flex-row justify-between items-center'}>
          <LeftOutline
            fontSize={24}
            data-testid={'btn-back'}
            onClick={goBack}
          />
          <LessonHeaderMenu lessonId={lesson.id!} />
        </div>
        <div className={'flex flex-col gap-2'}>
          <LessonEvaluation lesson={lesson} />
          <div
            className={
              'text-3xl overflow-ellipsis line-clamp-2 w-full overflow-hidden font-bold'
            }
          >
            {lesson.title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonHeader;
