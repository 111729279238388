import { useTranslation } from 'react-i18next';
import { MoreOutlined } from '@ant-design/icons';
import { type MenuProps } from 'antd';
import Dropdown from 'src/components/common/designSystem/Dropdown';
import Button from 'src/components/common/designSystem/Button';
import { logAnalyticsEvent } from 'src/modules/analytics';
import { useCurrentUser } from 'src/queries/user';
import { useNavigate } from 'react-router-dom';
import { User } from 'src/types/user.types';

export default function UserItemMenu({ user }: { user: User }) {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const menuItems: MenuProps['items'] = [
    {
      key: 'invite',
      label: t('invitations.invite'),
    },
  ];
  const onMenuClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'invite':
        logAnalyticsEvent('invite_user', {
          from_user: currentUser?.id,
          to_user: user.id,
        });
        navigate(`/child/${user.id}/other_device`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown
        placement="bottomRight"
        menu={{ onClick: onMenuClick, items: menuItems }}
      >
        <Button
          data-testid={'btn-more'}
          size="small"
          shape="circle"
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </>
  );
}
