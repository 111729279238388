import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';
import { useNotification } from 'src/contexts/NotificationContext';
import { PURCHASES_ERROR_CODE } from '@revenuecat/purchases-capacitor';
import { usePaywall } from 'src/contexts/PaywallContext';
import TrackedButton from 'src/components/common/designSystem/TrackedButton';

const commonClasses =
  'rounded-2xl h-auto border-none py-5 px-3 w-full text-base font-bold';

const PayButton = ({
  className,
  onPay,
  ...props
}: ButtonProps & { onPay?: () => void }) => {
  const { t } = useTranslation();
  const { isPremium, subscribePremiumWithoutTrial } = usePaywall();
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();

  useEffect(() => {
    if (isPremium && onPay) {
      onPay();
    }
  }, [isPremium, onPay]);

  const pay = async () => {
    try {
      setPaying(true);
      await subscribePremiumWithoutTrial();
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      console.error(error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  return (
    <TrackedButton
      loading={paying}
      onClick={pay}
      data-testid="btn-pay-premium"
      className={`${commonClasses} bg-gradient-to-r from-paywall-darkest-from to-paywall-darkest-to text-white`}
      {...props}
    />
  );
};

export default PayButton;
