import { useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import Input, { InputPassword } from 'src/components/common/designSystem/Input';
import Button from 'src/components/common/designSystem/Button';

import { authErrorCodeToMessage } from 'src/modules/firebase';

import type { AuthError } from 'firebase/auth';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNotification } from 'src/contexts/NotificationContext';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/common/designSystem/Footer';

const REGEX_UPPER_CASE = /[A-Z]+/;
const REGEX_DIGI_CASE = /[0-9]+/;

export default function ResetPassword() {
  const { t } = useTranslation();
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const handleUserChange = (field: string) => {
    return (event: ChangeEvent) => {
      const newValue = (event.target as HTMLInputElement).value;
      setUser({
        ...user,
        [field]: newValue,
      });
    };
  };

  const [resettingPassword, setResettingPassword] = useState(false);
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotification();

  const sendResetPasswordEmail = async () => {
    setResettingPassword(true);
    try {
      await FirebaseAuthentication.sendPasswordResetEmail({
        email: user.email,
      });
      showSuccess({
        message: t('login.resetPassword.sentEmailSuccess', {
          email: user.email,
        }),
      });
    } catch (error) {
      console.error(error);
      showError({
        message: authErrorCodeToMessage(error as AuthError),
        error,
      });
    } finally {
      setUser({
        ...user,
        email: '',
      });
      navigate('/');
      setResettingPassword(false);
    }
  };

  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');
  const [isMini, setIsMini] = useState(false);
  const [is1Number, setIs1Number] = useState(false);
  const [is1Uppercase, setIs1Uppercase] = useState(false);
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (newValue.length >= 8) setIsMini(true);
    else setIsMini(false);

    if (REGEX_DIGI_CASE.test(newValue)) setIs1Number(true);
    else setIs1Number(false);

    if (REGEX_UPPER_CASE.test(newValue)) setIs1Uppercase(true);
    else setIs1Uppercase(false);

    setUser({
      ...user,
      password: newValue,
    });
  };
  const [confirmPassword, setConfirmPassword] = useState('');
  const handelConfirmPasswordChange = (e: ChangeEvent) => {
    const newValue = (e.target as HTMLInputElement).value;
    setConfirmPassword(newValue);
  };
  const resetPassword = async () => {
    setResettingPassword(true);
    try {
      await FirebaseAuthentication.confirmPasswordReset({
        oobCode: oobCode!,
        newPassword: user.password,
      });
      showSuccess({
        message: t('login.resetPassword.passwordResetSuccess', {
          email: user.email,
        }),
      });
    } catch (error) {
      console.error(error);
      showError({
        message: authErrorCodeToMessage(error as AuthError),
        error,
      });
    } finally {
      setUser({
        ...user,
        password: '',
      });
      setConfirmPassword('');
      navigate('/');
      setResettingPassword(false);
    }
  };

  return (
    <LayoutWithHeader header={<Header backArrow />}>
      <div className="flex flex-col items-center h-full">
        <div className="mt-8 mb-4 text-xl font-semibold">
          {t('login.resetPassword.title')}
        </div>
        {oobCode ? (
          <>
            <Form
              name="resetPasswordForm"
              onFinish={resetPassword}
              className="flex-1 bg-transparent w-full flex flex-col justify-center items-center px-4 gap-4"
              disabled={resettingPassword}
              validateTrigger="onSubmit"
              layout="vertical"
            >
              <Form.Item
                label={t('login.passwordLabel')}
                className="w-full"
                validateTrigger="onBlur"
                rules={[
                  { required: true, message: t('login.passwordRequired') },
                ]}
              >
                <InputPassword
                  value={user.password}
                  onChange={handlePasswordChange}
                />
                <div className="mt-2 flex flex-col gap-2 text-sm">
                  <div className="flex items-center gap-2">
                    {isMini ? (
                      <IconMdiCheckCircle className="text-green-neutral" />
                    ) : (
                      <IconBiDash />
                    )}
                    <span>{t('login.signUp.passwordMinCharacters')}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {is1Number ? (
                      <IconMdiCheckCircle className="text-green-neutral" />
                    ) : (
                      <IconBiDash />
                    )}
                    <span>{t('login.signUp.password1Number')}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {is1Uppercase ? (
                      <IconMdiCheckCircle className="text-green-neutral" />
                    ) : (
                      <IconBiDash />
                    )}
                    <span>{t('login.signUp.password1Uppercase')}</span>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                label={t('login.signUp.confirmPasswordLabel')}
                className="w-full"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('login.signUp.confirmPasswordRequired'),
                  },
                  {
                    validator: async (_, value) => {
                      if (value === user.password) {
                        await Promise.resolve();
                        return;
                      }
                      return await Promise.reject(
                        new Error(t('login.signUp.confirmPasswordInvalid')),
                      );
                    },
                  },
                ]}
              >
                <InputPassword
                  value={confirmPassword}
                  onChange={handelConfirmPasswordChange}
                />
              </Form.Item>
            </Form>
            <Footer>
              <Button
                data-testid="btn-next"
                form="resetPasswordForm"
                type="primary"
                htmlType="submit"
                loading={resettingPassword}
              >
                {t('login.resetPassword.resetMyPassword')}
              </Button>
            </Footer>
          </>
        ) : (
          <>
            <Form
              name="resetPasswordForm"
              onFinish={sendResetPasswordEmail}
              className="flex-1 bg-transparent w-full flex flex-col justify-center items-center px-4 gap-4"
              disabled={resettingPassword}
              validateTrigger="onSubmit"
              layout="vertical"
            >
              <Form.Item
                name="email"
                label={t('login.emailLabel')}
                className="w-full"
                rules={[
                  { required: true, message: t('login.emailRequired') },
                  { type: 'email', message: t('login.emailInvalid') },
                ]}
              >
                <Input
                  autoComplete="email"
                  value={user.email}
                  onChange={handleUserChange('email')}
                />
              </Form.Item>
            </Form>
            <Footer>
              <Button
                data-testid="btn-next"
                form="resetPasswordForm"
                type="primary"
                htmlType="submit"
                loading={resettingPassword}
              >
                {t('login.resetPassword.startResetPassword')}
              </Button>
            </Footer>
          </>
        )}
      </div>
    </LayoutWithHeader>
  );
}
