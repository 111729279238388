import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  FirebaseAuthentication,
  User,
} from '@capacitor-firebase/authentication';
import { useOnlogin, useSignupUser } from 'src/queries/sessions';

import Spinner from 'src/components/common/designSystem/Spinner';
import { useQueryClient } from 'react-query';
import { useDeleteUser } from 'src/queries/users';
import { AxiosError } from 'axios';
import { Capacitor } from '@capacitor/core';
import { useNotification } from 'src/contexts/NotificationContext';
import { updateLocalStorageUserID } from 'src/utils/usersUtils';
import { getCurrentUserIdToken } from 'src/modules/firebase';
import { logAnalyticsEvent } from 'src/modules/analytics';

export interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  logout: () => Promise<void>;
  deleteAccount: () => Promise<void>;
  setMode: (mode: AuthMode) => void;
  createAnonymousUser: () => Promise<void>;
  isAnonymous: boolean;
}

export enum AuthMode {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

export const AuthContext = createContext<AuthContextType | null>(null);

const isSignup = (firebaseUser: User) => {
  if (!firebaseUser.metadata.creationTime) {
    return false;
  }
  if (!firebaseUser.metadata.lastSignInTime) {
    return true;
  }
  return (
    Math.abs(
      firebaseUser.metadata.creationTime - firebaseUser.metadata.lastSignInTime,
    ) < 60000
  );
};

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showError } = useNotification();
  const { t } = useTranslation();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const firebaseUser = useRef<User | null>(null);
  const firebaseAnonymousIdToken = useRef<string>();
  const mode = useRef<AuthMode>(AuthMode.LOGIN);

  const { mutateAsync: createUser } = useSignupUser();
  const { mutateAsync: onLogin } = useOnlogin();
  const { mutateAsync: deleteMyAccount } = useDeleteUser();

  const logout = async (): Promise<void> => {
    await FirebaseAuthentication.signOut();
    setIsLoggedIn(false);
    navigate('/');
    queryClient.clear();
  };

  const deleteAccount = async (): Promise<void> => {
    await deleteMyAccount();
    setIsLoggedIn(false);
    updateLocalStorageUserID(null);
    navigate('/');
    queryClient.clear();
  };

  const reloadUser = async () => {
    try {
      await FirebaseAuthentication.getIdToken();
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
    }
    setLoadingAuth(false);
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      reloadUser();
    }
  }, []);

  const tryCreateUser = useCallback(
    async (firebaseUser: User) => {
      try {
        const user = await createUser({
          newUserEmail: firebaseUser.email,
          anonymous_token: firebaseAnonymousIdToken.current,
          is_anonymous: firebaseUser.isAnonymous,
        });
        if (!firebaseUser.isAnonymous) {
          firebaseAnonymousIdToken.current = undefined;
          logAnalyticsEvent(isSignup(firebaseUser) ? 'signup' : 'signin', {
            method: firebaseUser.providerData.length
              ? firebaseUser.providerData[0]?.providerId
              : 'password',
          });
        }
        return user;
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 409) {
          logAnalyticsEvent('signin', {
            method: firebaseUser.providerData.length
              ? firebaseUser.providerData[0]?.providerId
              : 'password',
          });
          return;
        }
        await FirebaseAuthentication.deleteUser();
        throw error;
      }
    },
    [createUser],
  );

  const createAnonymousUser = useCallback(async () => {
    try {
      mode.current = AuthMode.SIGNUP;
      await FirebaseAuthentication.signInAnonymously();
      firebaseAnonymousIdToken.current = await getCurrentUserIdToken();
    } catch (error) {
      console.error(error);
      showError({
        message: t('login.errors.defaultError'),
        error,
      });
      setIsLoggedIn(false);
    }
  }, [showError, t]);

  // Firebase warn us if the user is signed-in or not, allow us to check the firebase auth status too.
  useEffect(() => {
    FirebaseAuthentication.addListener(
      'authStateChange',
      async ({ user: _firebaseUser }) => {
        firebaseUser.current = _firebaseUser;
        if (!_firebaseUser) {
          setIsLoggedIn(false);
          setLoadingAuth(false);
          return;
        }

        setLoadingAuth(true);
        try {
          if (mode.current === AuthMode.SIGNUP) {
            await tryCreateUser(_firebaseUser);
          } else if (mode.current === AuthMode.LOGIN) {
            if (firebaseAnonymousIdToken.current) {
              await onLogin({
                anonymous_token: firebaseAnonymousIdToken.current,
              });
            }
            logAnalyticsEvent('signin', {
              method: _firebaseUser.providerData.length
                ? _firebaseUser.providerData[0]?.providerId
                : 'password',
            });
          }
          if (!_firebaseUser.isAnonymous) {
            firebaseAnonymousIdToken.current = undefined;
          }
          await queryClient.refetchQueries('user');
          setIsLoggedIn(true);
        } catch (error) {
          console.error(error);
          showError({
            message: t('login.errors.defaultError'),
            error,
          });
          setIsLoggedIn(false);
        }
        setLoadingAuth(false);
        mode.current = AuthMode.LOGIN;
      },
    );

    return () => {
      FirebaseAuthentication.removeAllListeners();
    };
  }, [onLogin, queryClient, showError, t, tryCreateUser]);

  const setMode = useCallback((newMode: AuthMode) => {
    mode.current = newMode;
  }, []);

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    logout,
    deleteAccount,
    setMode,
    createAnonymousUser,
    isAnonymous: firebaseUser.current?.isAnonymous ?? false,
  };

  return (
    <AuthContext.Provider value={value}>
      {loadingAuth ? <Spinner className="w-full h-full" size="xl" /> : children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
