import { useTranslation } from 'react-i18next';
import LessonsSubjectList from 'src/components/LessonsLists/LessonsSubjectList';
import { allSubjects, nonSpanishSubjects } from 'src/utils/constants';
import { useFlags } from 'src/contexts/FlagsContext';
import AddLessonFloatingButton from 'src/components/LessonsLists/AddLessonFloatingButton';
import { Tabs, Tab } from 'src/components/common/designSystem/Tabs';
import { ReactElement } from 'react';
import type { TabProps } from 'antd-mobile';

const LessonsPage = () => {
  const { t } = useTranslation();

  const { display_spanish_subject } = useFlags();
  const subjectsToDisplay = display_spanish_subject
    ? allSubjects
    : nonSpanishSubjects;

  const lessonsTabs: ReactElement<TabProps>[] = subjectsToDisplay.map(
    ({ key, labelKey }) => (
      <Tab key={key} title={t(labelKey)}>
        <LessonsSubjectList subject={key} />
      </Tab>
    ),
  );
  lessonsTabs.unshift(
    <Tab key={'running'} title={'En cours'}>
      <LessonsSubjectList />
    </Tab>,
  );

  return (
    <div className={'flex flex-1 flex-col text-structural-darkest'}>
      <div className={'text-2xl font-bold text-structural-darkest my-4'}>
        {t('lessons.title')}
      </div>

      <Tabs>{...lessonsTabs}</Tabs>

      <AddLessonFloatingButton />
    </div>
  );
};

export default LessonsPage;
