import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubjectCard from 'src/components/Subject/SubjectCard';
import { allSubjects, nonSpanishSubjects } from 'src/utils/constants';
import { useFlags } from 'src/contexts/FlagsContext';
import AddLessonModal from 'src/components/AddLesson/AddLessonModal';
import { usePaywall } from 'src/contexts/PaywallContext';
import { LessonSubject } from 'src/types/lesson.types';

export default function SubjectsBoxes() {
  const { t } = useTranslation();
  const { display_spanish_subject } = useFlags();
  const [selectedSubject, setSelectedSubject] = useState<LessonSubject>();
  const subjects = display_spanish_subject ? allSubjects : nonSpanishSubjects;
  const { isOutOfTrial, showPaywall } = usePaywall();

  const onSubjectClick = (subject: LessonSubject) => {
    if (isOutOfTrial) {
      showPaywall();
      return;
    }
    setSelectedSubject(subject);
  };
  const handleClose = () => {
    setSelectedSubject(undefined);
  };

  return (
    <>
      <div className="w-full mx-auto bg-white rounded-3xl p-4">
        <div className="flex flex-col gap-4">
          <div>
            <div className="text-lg font-bold text-structural-darker">
              {t('subjects.addLesson')}
            </div>
          </div>
          <div className="flex flex-row items-start gap-x-4 no-margin-layout-overflow-x">
            {subjects.map(subject => {
              return (
                <SubjectCard
                  key={subject.key}
                  subject={subject.key}
                  onClick={() => {
                    onSubjectClick(subject.key);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <AddLessonModal
        isOpen={Boolean(selectedSubject)}
        onClose={handleClose}
        subject={selectedSubject}
      />
    </>
  );
}
