import { useGetNotifications } from 'src/queries/notifications';
import Spinner from 'src/components/common/designSystem/Spinner';
import NotificationCard from 'src/components/Notifications/NotificationCard';
import EmptyNotificationCard from 'src/components/Notifications/NotificationCard/EmptyNotificationCard';
import { InAppNotification } from 'src/types/notification.types';

const NotificationsList = () => {
  const { data: notifications, isLoading } = useGetNotifications();
  if (isLoading) {
    return <Spinner />;
  }

  const flatNotifications = notifications?.pages.flatMap(page => page.items);

  if (!flatNotifications?.length) {
    return <EmptyNotificationCard />;
  }

  return (
    <div className={'flex flex-col gap-4'}>
      {flatNotifications
        ?.sort(
          (
            notification1: InAppNotification,
            notification2: InAppNotification,
          ) => {
            return (
              new Date(notification2.created_at).getTime() -
              new Date(notification1.created_at).getTime()
            );
          },
        )
        .map(notification => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
    </div>
  );
};

export default NotificationsList;
