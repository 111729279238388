import {
  PACKAGE_TYPE,
  PRODUCT_TYPE,
  Purchases,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';

export const fakePackage: PurchasesPackage = {
  identifier: 'premium_trial',
  packageType: PACKAGE_TYPE.MONTHLY,
  product: {
    title: 'Premium',
    description: 'Premium subscription',
    price: 0.99,
    pricePerMonth: 0.99,
    pricePerWeek: 0.99,
    pricePerMonthString: '$0.99',
    pricePerYear: 0.99,
    pricePerWeekString: '$0.99',
    pricePerYearString: '$0.99',
    currencyCode: 'USD',
    identifier: 'premium_trial',
    introPrice: null,
    productType: PRODUCT_TYPE.AUTO_RENEWABLE_SUBSCRIPTION,
    discounts: null,
    productCategory: null,
    priceString: '$0.99',
    defaultOption: null,
    subscriptionOptions: null,
    subscriptionPeriod: null,
    presentedOfferingContext: null,
    presentedOfferingIdentifier: null,
  },
  presentedOfferingContext: {
    offeringIdentifier: 'default',
    targetingContext: null,
    placementIdentifier: null,
  },
  offeringIdentifier: 'default',
};

export const getFakeCustomerInfo = async () => {
  // @ts-ignore
  if (!import.meta.env.VITE_CI || !window.getFakeCustomerInfo) {
    return undefined;
  }
  // @ts-ignore
  return window.getFakeCustomerInfo();
};

export const fakeSubscribePremiumWithTrial = async () => {
  const result = await Purchases.purchasePackage({ aPackage: fakePackage });
  const fakeCustomerInfo = await getFakeCustomerInfo();
  if (fakeCustomerInfo) {
    return {
      ...result,
      customerInfo: fakeCustomerInfo,
    };
  }
  return result;
};

export const fakeSubscribePremiumWithoutTrial = async () => {
  const result = await Purchases.purchasePackage({ aPackage: fakePackage });
  const fakeCustomerInfo = await getFakeCustomerInfo();
  if (fakeCustomerInfo) {
    return {
      ...result,
      customerInfo: fakeCustomerInfo,
    };
  }
  return result;
};
