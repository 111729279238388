import { useParams, useSearchParams } from 'react-router-dom';
import useEndOnboardingParentAndSwitchToChildOnboarding from 'src/hooks/useEndOnboardingParentAndSwitchToChildOnboarding';
import Spinner from 'src/components/common/designSystem/Spinner';
import { usePaywall } from 'src/contexts/PaywallContext';
import { useCallback, useEffect } from 'react';
import PaywallScreenPay from 'src/components/Paywall/PaywallScreenPay';
import { DeviceType } from 'src/components/Onboarding/ParentChildDevice';
import useEndOnboardingParent from 'src/hooks/useEndOnboardingParent';
import { logAnalyticsEvent } from 'src/modules/analytics';

const OnboardingParentChildPaywallPay = () => {
  const { childId } = useParams();
  const {
    endOnboarding: endOnboardingParentAndSwitchToChildOnboarding,
    isEnding: isEndingOnboardingParentAndSwitchToChildOnboarding,
  } = useEndOnboardingParentAndSwitchToChildOnboarding({
    childId: Number(childId),
  });
  const {
    endOnboarding: endOnboardingParent,
    isEnding: isEndingOnboardingParent,
  } = useEndOnboardingParent();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get('deviceType');
  const { isPremium } = usePaywall();

  const endOnboarding = useCallback(async () => {
    if (deviceType === DeviceType.other) {
      return endOnboardingParent();
    }
    endOnboardingParentAndSwitchToChildOnboarding();
  }, [
    deviceType,
    endOnboardingParent,
    endOnboardingParentAndSwitchToChildOnboarding,
  ]);

  useEffect(() => {
    if (isPremium) {
      endOnboarding();
    }
  }, [isPremium, endOnboarding]);

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen2_parent',
    });
  }, []);

  if (
    isEndingOnboardingParentAndSwitchToChildOnboarding ||
    isEndingOnboardingParent
  ) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return <PaywallScreenPay onSkip={endOnboarding} />;
};

export default OnboardingParentChildPaywallPay;
